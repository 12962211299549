import { Component, HostBinding, Input, OnChanges } from '@angular/core';

import { VoxelValidateParameterService } from './../../services/validate-parameter/validate-parameter.service';

export type ICardSize = 's' | 'm' | 'l';

/**
 * Componente Card
 *
 * Auxilia na organizaÃ§Ã£o, visualizaÃ§Ã£o e agrupamento de outros elementos
 * Possui trÃªs nÃ­veis de profundidade: NÃ­vel 0 - s | NÃ­vel 1 - m | NÃ­vel 2 - l
 * NÃ­vel S: background branco e elementos em Ãºltimo plano para card e banners
 * NÃ­vel M: background Areia para profundidade em card e banners
 * NÃ­vel L: plano para dropdowns, alertas e outros elementos exibidos acima
 *
 * <example-url>../../demo/index.html#/portfolio/card?componentOnly=true</example-url>
 *
 * @example NÃ­vel 0 | S - com click
 * <voxel-card size="s" (click)="onClick($event)" [attr.hasClick]="true">Ãrea de desenvolvimento</voxel-card>
 *
 * @example NÃ­vel 1 | m
 * <voxel-card size="m">Ãrea de desenvolvimento</voxel-card>
 *
 * @example NÃ­vel 2 | l
 * <voxel-card size="l">Ãrea de desenvolvimento</voxel-card>
 */
@Component({
  selector: 'voxel-card',
  styleUrls: ['./card.component.scss'],
  template: `
    <div>
      <ng-content></ng-content>
    </div>
  `,
})
export class VoxelCardComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-card';

  /**
   * DefiniÃ§ao do nÃ­vel do card
   */
  @Input()
  size: ICardSize = 'm';

  /**
   * Exibe estilo ao passar o mouse, e ao clicar, sinalizando interaÃ§Ã£o
   */
  @Input()
  hasClick = false;

  /**
   * @internal
   */
  @HostBinding('class') itemClasses: string[] = [];

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
  ) { }

  /**
   * @internal
   */
  ngOnChanges() {
    this.applySize();
  }

  /**
   * @internal
   */
  applySize() {
    this.validateParam.validatePropertyOnObject(this, VoxelCardComponent.selector, [
      'size',
    ]);
    this.itemClasses = [`voxel-card voxel-card--${this.size}`];
  }
}
