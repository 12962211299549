import { InjectionToken, Optional } from '@angular/core';

/**
 * Segmentos ItaÃº
 */
export enum SegmentTypes {
  Varejo = 'varejo',
  Personnalite = 'personnalite',
  Uniclass = 'uniclass',
  Empresas = 'empresas',
  Itaucard = 'itaucard',
  Private = 'private',
  ParceiroItaucard = 'parceiro-itaucard',
  ParceiroCredicard = 'parceiro-credicard',
  ParceiroHipercard = 'parceiro-hipercard',
  ParceiroMagazineLuiza = 'parceiro-magazine-luiza',
}

/**
 * @internal
 * Token de InjeÃ§Ã£o de DependÃªncia que contÃªm o valor do {@link SegmentTypes} padrÃ£o.
 */
export const SegmentTypeDefault = new InjectionToken<SegmentTypes>('SegmentTypeDefault');

/**
 * @internal
 * Token de InjeÃ§Ã£o de DependÃªncia que permite configuraÃ§Ã£o opcional do valor do {@link SegmentTypes} SegmentTypeDefault.
 */
export const SegmentTypeConfig = new InjectionToken<SegmentTypes>('SegmentTypeConfig');

/**
 * @internal
 * Factory function para criar o valor do SegmentTypeDefault
 */
export function segmentTypeDefaultFactory(
  segment?: SegmentTypes,
) {
  return segment || SegmentTypes.Varejo;
}

/**
 * @internal
 * Provider do valor do SegmentTypeDefault
 */
export const SegmentTypeDefaultProvider = {
  provide: SegmentTypeDefault,
  useFactory: segmentTypeDefaultFactory,
  deps: [
    [ new Optional(), SegmentTypeConfig ],
  ],
};
