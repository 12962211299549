import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VoxelSegmentModule } from './../../services/segment/segment.module';
import { VoxelNavigationComponent } from './navigation.component';

export * from './navigation.component';

@NgModule({
  imports: [
    CommonModule,
    VoxelSegmentModule,
  ],
  declarations: [
    VoxelNavigationComponent,
  ],
  exports: [
    VoxelNavigationComponent,
  ],
})

export class VoxelNavigationModule { }
