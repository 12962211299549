import { InjectionToken, Optional } from '@angular/core';

/**
 * Tipos de notificaÃ§Ã£o de erros de validaÃ§Ã£o de parametros dos componentes
 */
export enum ValidateParameterNotificationTypes {
  /**
   * Nenhum erro de validaÃ§Ã£o serÃ¡ mostrado
   */
  Ignore,
  /**
   * Erro serÃ¡ notificado com um throw de um `new Error`
   */
  ThrowError,
  /**
   * Erro serÃ¡ notificado com um `console.error`
   */
  ConsoleLog,
}

/**
 * @internal
 * Token de InjeÃ§Ã£o de DependÃªncia para escolher o {@link ValidateParameterNotificationTypes} a ser usado.
 */
export const ValidateParameterNotificationTypeDefault =
  new InjectionToken<ValidateParameterNotificationTypes>('ValidateParameterNotificationTypeDefault');

/**
 * @internal
 * Token de InjeÃ§Ã£o de DependÃªncia que permite configuraÃ§Ã£o opcional do valor do
 * {@link ValidateParameterNotificationTypes} ValidateParameterNotificationTypeDefault.
 */
export const ValidateParameterNotificationTypeConfig
  = new InjectionToken<ValidateParameterNotificationTypes>('ValidateParameterNotificationTypeConfig');

/**
 * @internal
 * Factory function para criar o valor do ValidateParameterNotificationTypeDefault
 */
export function notificationTypeDefaultFactory(
  notificationType?: ValidateParameterNotificationTypes,
) {
  return notificationType === undefined || notificationType === null
    ? ValidateParameterNotificationTypes.ThrowError : notificationType;
}

/**
 * @internal
 * Provider do valor do ValidateParameterNotificationTypeDefault
 */
export const ValidateParameterNotificationTypeDefaultProvider = {
  provide: ValidateParameterNotificationTypeDefault,
  useFactory: notificationTypeDefaultFactory,
  deps: [
    [ new Optional(), ValidateParameterNotificationTypeConfig ],
  ],
};
