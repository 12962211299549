import { AfterViewChecked, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { VoxelNumberFormatterService } from './../../services/number-formatter/number-formatter.service';

export interface ICurrencyFormaterEvent {
  event: Event;
  value: string;
  cleanValue: string;
}

/**
 * Diretiva para validaÃ§Ã£o formato de moeda
 *
 * @example <input voxelCurrencyFormatter (currencyChange)="formatCurrency($event)">
 */
@Directive({
  selector: '[voxelCurrencyFormatter]',
})
export class VoxelCurrencyFormatterDirective implements AfterViewChecked {

  @Input()
  currency: any;

  @Output()
  currencyChange = new EventEmitter<ICurrencyFormaterEvent>();

  /**
   * @internal
   */
  constructor(
    private element: ElementRef,
    private numberFormatter: VoxelNumberFormatterService,
  ) {}

  @HostListener('input', ['$event'])
  onEvent($event: Event) {
    if (this.currency !== 'currency') {
      return;
    }
    const inputHtmlElement = $event.target as HTMLInputElement;
    const valueWithMask = this.numberFormatter.currency(inputHtmlElement.value).formatValue;
    const cleanValue = String(valueWithMask).replace(/\D/g, '');

    inputHtmlElement.value = valueWithMask;
    this.setCursorAtEnd(inputHtmlElement);
    this.currencyChange.emit({
      event: $event,
      value: valueWithMask,
      cleanValue,
    });
  }

  /**
   * NecessÃ¡rio para disparar manualmente o evento de click para formatar o campo.
   * O evento de input disparado via cÃ³digo nÃ£o Ã© aceito no Android 4.1
   */
  @HostListener('click', ['$event'])
  onClickEvent($event: Event) {
    this.onEvent($event);
  }

  /**
   * @internal
   */
  ngAfterViewChecked() {
    if (this.currency === 'currency' && this.dontHaveValue()) {
      this.element.nativeElement.value = 'R$ 0,00';
    }

    if (this.currency === 'currency' && this.element.nativeElement.value !== undefined) {
      this.element.nativeElement.value = this.numberFormatter.currency(this.element.nativeElement.value).formatValue;
    }
  }

  /**
   * @internal
   */
  dontHaveValue() {
    const { value } = this.element.nativeElement;
    return value === '' || value === undefined || value === 'R$ ' || value === '0';
  }

  /**
   * @internal
   */
  setCursorAtEnd(target: HTMLInputElement) {
    // utilizado setTimeout para esperar a renderizaÃ§Ã£o e mudar o cursor para o final
    // essa sugestÃ£o foi sugerida na issue dessa mascara do angular 1 por um cara que estava utilizando ionic 2
    // https://github.com/assisrafael/angular-input-masks/issues/104

    setTimeout(() => {
      this.element.nativeElement.selectionStart = target.value.length;
      this.element.nativeElement.selectionEnd = target.value.length;
    });
  }
}
