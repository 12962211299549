import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelSegmentModule } from './../../services/segment/segment.module';
import { VoxelValidateParameterModule } from './../../services/validate-parameter/validate-parameter.module';
import { VoxelOptionsComponent } from './options.component';

export * from './options.component';

@NgModule({
  imports: [
    CommonModule,
    VoxelValidateParameterModule,
    VoxelSegmentModule,
  ],
  declarations: [VoxelOptionsComponent],
  exports: [VoxelOptionsComponent],
})
export class VoxelOptionsModule { }
