import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VoxelValidateContentDirective } from './validate-content.directive';
import { VoxelValidateParameterModule } from '../../services/validate-parameter/validate-parameter.module';

export * from './validate-content.directive';

@NgModule({
  imports: [
    CommonModule,
    VoxelValidateParameterModule,
  ],
  declarations: [VoxelValidateContentDirective],
  exports: [VoxelValidateContentDirective],
})
export class VoxelValidateContentDirectiveModule { }
