import { NgModule } from '@angular/core';

import { VoxelValidateParameterModule } from '../../services/validate-parameter/validate-parameter.module';
import { VoxelAutoTabDirective } from './auto-tab-directive';

@NgModule({
  imports: [
    VoxelValidateParameterModule,
  ],
  declarations: [VoxelAutoTabDirective],
  exports: [VoxelAutoTabDirective],
})
export class VoxelAutoTabDirectiveModule { }

export * from './auto-tab-directive';
