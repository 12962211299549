import { Injectable } from '@angular/core';

/**
 * ServiÃ§o para validaÃ§Ã£o de mascaras
 */
@Injectable()
export class VoxelCustomMaskValidatorService {

  /**
   * PadrÃ£o para operadores de mÃ¡scara
   * 0  Somente dÃ­gitos (nÃºmeros)
   * S  Somente letras maiÃºsculas e minÃºsculas
   * A  Letras maiÃºsculas, letras minÃºsculas e dÃ­gitos (nÃºmeros)
   */
  maskOperators = {
    0 : /\d/,
    S : /[a-zA-Z]/,
    A : /[a-z-A-Z0-9]/,
  } as any;

  /**
   * ValidaÃ§Ã£o de mÃ¡scara customizada
   * @param value Recebe o valor inputado
   * @param customMask Recebe a mÃ¡scara customizada
   * @returns Passou um nÃ£o na validaÃ§Ã£o
   *
   * @example
   * <caption></caption>
   * ```js
   * this.validateCustomMask = validateCustomMask('EVT-2018', 'SSS-0000');
   * ```
   */
  validateCustomMask(value: string, customMask: string): boolean {
    let stringPattern = '';

    for (let i = 0; i < customMask.length; i++) {

      let isRegex = false;

      for (const operator in this.maskOperators) {

        // tslint:disable-next-line:early-exit
        if (customMask.charAt(i) === operator) {
          stringPattern += this.maskOperators[operator].toString().replace(/^\/(.*)\/$/, '$1');
          isRegex = true;
          break;
        }
      }

      if (!isRegex) {

        stringPattern += '\\' + customMask.charAt(i) + '?';
      }
    }

    return new RegExp('^' + stringPattern + '$').test(value);
  }

  /**
   * Retorna o padrÃ£o operadores configurado para criaÃ§Ã£o de mÃ¡scara customizada
   */
  getMaskOperators() {
    return this.maskOperators;
  }
}
