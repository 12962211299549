import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { SegmentTypes, VoxelSegmentService } from './../../services/segment/segment.module';
import { VoxelValidateParameterService } from './../../services/validate-parameter/validate-parameter.module';

/**
 * Executa uma aÃ§Ã£o especÃ­fica que deve ser claramente expressa por sua literal.
 * Aqui trataremos dos botÃµes primÃ¡rios e secundÃ¡rios
 * (os botÃµes de navegaÃ§Ã£o e de aÃ§Ã£o sÃ£o tratados no item "Barra de navegaÃ§Ã£o" e os de "Ir para").
 *
 * <example-url>../../demo/index.html#/portfolio/button?componentOnly=true</example-url>
 *
 * @example Normal
 * <voxel-button
 *   id="button1"
 *   label="label"
 *   a11yLabel="BotÃ£o primÃ¡rio">
 *  </voxel-button>
 *
 * @example Desabilitado
 * <voxel-button
 *   id="button2"
 *   label="desabilitado"
 *   a11yLabel="BotÃ£o primÃ¡rio desabilitado"
 *   disabled="true">
 * </voxel-button>
 *
 * @example Desabilitado
 * <voxel-button
 *   id="button3"
 *   label="label"
 *   a11yLabel="BotÃ£o primÃ¡rio com Ã­cone"
 *   icon="icon-itaufonts_saldo"
 *   iconDirection="left">
 * </voxel-button>
 *
 * @example SecundÃ¡rio
 * <voxel-button
 *   id="button4"
 *   label="secundÃ¡rio"
 *   a11yLabel="BotÃ£o secundÃ¡rio"
 *   secondary="true">
 * </voxel-button>
 *
 * @example Ghost Esquerda
 * <voxel-button
 *   id="button5"
 *   label="ghost"
 *   ghost="left"
 *   a11yLabel="BotÃ£o ghost lado esquerdo">
 * </voxel-button>
 *
 * @example Ghost com Ã­cone parametrizÃ¡vel
 * <voxel-button
 *   id="button6"
 *   label="ghost"
 *   ghost="left"
 *   a11yLabel="BotÃ£o ghost lado esquerdo com Ã­cone parametrizÃ¡vel"
 *   icon="icon-itaufonts_full_microfone">
 * </voxel-button>
 *
 * @example Ghost com Ã­cone a direita
 * <voxel-button
 *   id="button7"
 *   label="ghost"
 *   ghost="right"
 *   a11yLabel="BotÃ£o ghost lado direito">
 * </voxel-button>
 */
@Component({
  selector: 'voxel-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class VoxelButtonComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-button';

  /**
   * Label do botÃ£o
   */
  @Input()
  label: string;

  /**
   * Label para acessibilidade do botÃ£o
   */
  @Input()
  a11yLabel: string;

  /**
   * Indica se o botÃ£o ficara desabilidato.
   * Pode ser usado para configurar o estado inicial do componente
   */
  @Input()
  disabled = false;

  /**
   * Utilizado para habilitar a acessibilidade que informa se o botÃ£o esta pressionado ou nÃ£o
   *
   */
  @Input()
  ariaPressed = false;

  /**
   * Pode ser usado para trocar o estilo do botÃ£o para secundÃ¡rio.
   *
   */
  @Input()
  secondary = false;

  /**
   * Acessibilidade do botÃ£o para informar o estado ativo/inativo
   */
  @Input()
  active = false;

  /**
   * Pode ser usado para trocar o estilo do botÃ£o para posicionar
   * o mesmo sempre na parte inferior
   */
  @Input()
  positionBotton = false;

  /**
   * Classe CSS referente ao Ã­cone
   */
  @Input()
  icon?: string;

  /**
   * Pode ser usado para trocar o estilo do botÃ£o para ghost.
   * OpÃ§Ãµes para uso:
   * * left - icone de seta para o lado esquerdo
   * * right - icone de seta para o lado direito
   */
  @Input()
  ghost?: 'left' | 'right';

  /**
   * Evento disparado ao clicar no botÃ£o que emite o evento gerado
   */
  @Output()
  click = new EventEmitter<Event>();

  /**
   * @internal
   */
  buttonClasses = this.getCSSClasses();

  /**
   * @internal
   */
  segment: Observable<SegmentTypes> = this.segmentService.segment;

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
    private segmentService: VoxelSegmentService,
  ) { }

  /**
   * @internal
   */
  ngOnChanges(changes: SimpleChanges) {
    this.validateParam.validatePropertyOnObject(
      this,
      VoxelButtonComponent.selector,
      ['label', 'a11yLabel'],
    );

    if (changes.icon || changes.ghost) {
      this.buttonClasses = this.getCSSClasses();
    }
  }

  /**
   * @internal
   */
  private getCSSClasses() {
    return {
      'ghost__right': this.ghost === 'right',
      'ghost__left ': this.ghost === 'left' && !this.icon,
      'ghost__left_icon': this.ghost === 'left' && this.icon,
      'isIcon': this.icon,
    };
  }

  /**
   * @internal
   */
  onClick($event: Event) {
    this.click.emit($event);
  }

}
