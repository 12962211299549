import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

export interface ICpfCnpjEvent {
  event: Event;
  value: string;
  clearMask: string;
}

/**
 * Diretiva para validaÃ§Ã£o de CPF e CPNJ diretamente em um elemento
 *
 * @example CPF
 * <input voxelCpfCnpj maskType="cpf" (cpfCnpjValue)="emitCPF($event)">
 *
 * @example CNPJ
 * <input voxelCpfCnpj maskType="cnpj" (cpfCnpjValue)="emitCNPJ($event)">
 *
 * @example CPF e CNPJ
 * <input voxelCpfCnpj maskType="cpf-cnpj" (cpfCnpjValue)="emitCPFandCNPJ($event)">
 */
@Directive({
  selector: '[voxelCpfCnpj]',
})
export class VoxelCpfCnpjDirective {

  @Input()
  maskType?: string;

  @Output()
  cpfCnpjValue = new EventEmitter<ICpfCnpjEvent>();

  private maxLengthCnpjMask = 14;

  private maxLengthCpfMask = 11;

  private maskTypeCpf = 'cpf';

  private maskTypeCnpj = 'cnpj';

  private maskTypeCpfCnpj = 'cpf-cnpj';

  @HostListener('input', ['$event'])
  onKeyUp($event: Event) {
    if (this.dontApplyMask()) {
      return;
    }

    const inputHtmlElement = $event.target as HTMLInputElement;

    let value = inputHtmlElement.value.replace(/\D/g, '');

    if (this.hasNotValidLength(value)) {
      value = this.validateMaxLength(value);
    }

    if (value.length <= this.maxLengthCpfMask && this.maskType !== this.maskTypeCnpj) {
      value = this.applyCpfMask(value);
    } else if (
        this.maskType === this.maskTypeCnpj ||
        (value.length > this.maxLengthCpfMask && this.maskType === this.maskTypeCpfCnpj)
      ) {
      value = this.applyCnpjMask(value);
    }

    inputHtmlElement.value = value;
    this.cpfCnpjValue.emit({
      event: $event,
      value,
      clearMask: value.replace(/\D/g, ''),
    });
  }

  /**
   * @internal
   */
  dontApplyMask() {
    return this.maskType !== this.maskTypeCpf &&
      this.maskType !== this.maskTypeCnpj &&
      this.maskType !== this.maskTypeCpfCnpj;
  }

  /**
   * @internal
   */
  hasNotValidLength(value: string) {
    if (this.maskType === this.maskTypeCpf) {
      return value.length > this.maxLengthCpfMask;
    }

    if (this.maskType === this.maskTypeCnpj || this.maskType === this.maskTypeCpfCnpj) {
      return value.length > this.maxLengthCnpjMask;
    }

    return false;
  }

  /**
   * @internal
   */
  validateMaxLength(value: string) {
    let currentMaxLength;

    if (this.maskType === this.maskTypeCpf) {
      currentMaxLength = this.maxLengthCpfMask;
    }

    if (this.maskType === this.maskTypeCnpj || this.maskType === this.maskTypeCpfCnpj) {
      currentMaxLength = this.maxLengthCnpjMask;
    }

    return value.substring(0, currentMaxLength);
  }

  /**
   * @internal
   */
  applyCpfMask(value: string) {
    return value
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }

  /**
   * @internal
   */
  applyCnpjMask(value: string) {
    return value
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }
}
