import { Component, Input, OnChanges } from '@angular/core';

import { ValidateParameterNotifierService } from '../../services/validate-parameter/validate-parameter-notifier.service';

export type ITooltipType = 'tip' | 'info' | 'warning' | 'error';
export type ITooltipPosition = 'top' | 'bottom' | 'left' | 'right';
export type ITooltipAlignArrow = 'start' | 'middle' | 'end';

/**
 * Componente de Tooltip
 *
 * Tooltips sÃ£o usados para que o usuÃ¡rio possa ler uma informaÃ§Ã£o adicional.
 * O componente Ã© visualizado de forma estÃ¡tica, se desejar exibir a `tooltip`
 * dinamicamente nos eventos de `hover`, `focus` e etc, utilize pela diretiva {@link VoxelTooltipDirective}.
 *
 * <example-url>../../demo/index.html#/portfolio/tooltip?componentOnly=true</example-url>
 *
 * @example Dica
 * <voxel-tooltip tooltip="texto da tooltip">?</voxel-tooltip>
 *
 * @example InformaÃ§Ã£o adicional
 * <voxel-tooltip
 *   text="texto da tooltip"
 *   type="info">?
 * </voxel-tooltip>
 *
 * @example Aviso
 * <voxel-tooltip
 *   text="texto da tooltip"
 *   type="warning">?
 * </voxel-tooltip>
 *
 * @example Erro
 * <voxel-tooltip
 *   text="texto da tooltip"
 *   type="error">?
 * </voxel-tooltip>
 *
 */
@Component({
  selector: 'voxel-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class VoxelTooltipComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-tooltip';

  constructor(
    private validationNotifier: ValidateParameterNotifierService,
  ) {}

  /**
   * Texto a ser exibido na tooltip
   */
  @Input()
  text = '';

  /**
   * Define o tipo da tooltip.
   */
  @Input()
  type: ITooltipType = 'tip';

  /**
   * Define a posiÃ§Ã£o em relaÃ§Ã£o ao elemento, que a tooltip serÃ¡ exibida.
   */
  @Input()
  position: ITooltipPosition = 'top';

  /**
   * Define o alinhamento da seta da tooltip.
   */
  @Input()
  alignArrow: ITooltipAlignArrow = 'middle';

  /**
   * @internal
   */
  ngOnChanges() {
    if (this.text.length > 120) {
      this.text = '';
      this.validationNotifier.notify('property text must have a maximum of 120 characters');
    }
  }
}
