import { ModuleWithProviders, NgModule } from '@angular/core';

import {
  ValidateParameterNotificationTypeConfig,
  ValidateParameterNotificationTypes,
  ValidateParameterNotificationTypeDefaultProvider,
} from './validate-parameter/validate-parameter-notifier.interface';
import {
  SegmentTypes,
  SegmentTypeConfig,
  SegmentTypeDefaultProvider,
} from './segment/segment.interface';

/**
 * Dados possÃ­veis de serem configurados globalmente para o Voxel
 */
export interface IVoxelConfig {
  /**
   * Projeto esta executando em modo de produÃ§Ã£o?
   */
  production?: boolean;
  /**
   * Tipo de notificaÃ§Ã£o de erro de validaÃ§Ã£o de parÃ¢metro desejada
   */
  notificationType?: ValidateParameterNotificationTypes;
  /**
   * Segmento de produto ItaÃº padrÃ£o da aplicaÃ§Ã£o
   */
  segment?: SegmentTypes;
}

/**
 * MÃ³dulo para configuraÃ§Ãµes globais do Voxel.
 * A importaÃ§Ã£o desse mÃ³dulo Ã© obrigatÃ³ria e deve ser feita no mÃ³dulo raiz da aplicaÃ§Ã£o, utilizando o mÃ©todo `.forRoot()`
 */
@NgModule({
  providers: [
    SegmentTypeDefaultProvider,
    ValidateParameterNotificationTypeDefaultProvider,
  ],
})
export class VoxelConfigModule {

  /**
   * MÃ©todo estÃ¡tico para gerar as configuraÃ§Ãµes de mÃ³dulo conforme parÃ¢metros recebidos
   *
   * @param config ConfiguraÃ§Ã£o desejada para o projeto
   */
  static forRoot(config: IVoxelConfig): ModuleWithProviders<VoxelConfigModule> {

    return {
      ngModule: VoxelConfigModule,
      providers: [
        {
          provide: ValidateParameterNotificationTypeConfig,
          useValue: config.production ? ValidateParameterNotificationTypes.Ignore : config.notificationType,
        },
        {
          provide: SegmentTypeConfig,
          useValue: config.segment,
        },
      ],
    };
  }

}
