import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { SegmentTypes, VoxelSegmentService } from './../../services/segment/segment.module';
import { VoxelValidateParameterService } from './../../services/validate-parameter/validate-parameter.service';

/**
 * Dados do evento disparado ao clicar no radio button
 */
export interface IRadioButtonEvent {
  /**
   * Evento nativo emitido pelo click
   */
  $event: Event;
  /**
   *  IdentificaÃ§Ã£o Ãºnica do input de radio
   */
  id: string | number;
  /**
   *  Label usado no input de radio
   */
  label: string;
  /**
   *  AtivaÃ§Ã£o do radio
   */
  checked: boolean;
  /**
   *  Desabilitar o componenente
   */
  disabled: boolean;
  /**
   *  Nome usado no input de radio
   */
  name: string;
  /**
   *  Valor do radio no momento do evento
   */
  value: string | number;
  /**
   *  VerificaÃ§Ã£o se o radio Ã© estÃ¡ vÃ¡lido
   */
  valid: boolean;
}

/**
 * Componente Radio Button
 *
 * Permite que o usuÃ¡rio selecione uma Ãºnica opÃ§Ã£o dentre vÃ¡rias, em um conjunto.
 *
 * <example-url>../../demo/index.html#/portfolio/radiobutton?componentOnly=true</example-url>
 *
 * @example Sem SeleÃ§Ã£o
 * <voxel-radio-button
 *   label="Radio button sem seleÃ§Ã£o"
 *   id='1' name="test"
 *   value="1">
 * </voxel-radio-button>
 *
 * @example Selecionado
 * <voxel-radio-button
 *   label="Radio button selecionado"
 *   id='2'
 *   [checked]="true"
 *   name="test"
 *   value="2">
 * </voxel-radio-button>
 *
 * @example Desabilitado
 * <voxel-radio-button
 *   label="Radio button desabilitado"
 *   id='3'
 *   [disabled]="true"
 *   value="3"
 *   name="test">
 * </voxel-radio-button>
 *
 * @example Sem label
 * <voxel-radio-button
 *   id='4'
 *   name="test"
 *   value="4">
 * </voxel-radio-button>
 *
 * @example Com a primeira letra maiÃºscula
 * <voxel-radio-button
 *   label="Produto Com Nome PrÃ³prio"
 *   firstLetterUppercase="true"
 *   id='5'
 *   name="test"
 *   value="5">
 * </voxel-radio-button>
 */
@Component({
  selector: 'voxel-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class VoxelRadioButtonComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-radio-button';

  /**
   * Atributo de IdentificaÃ§Ã£o do componente.
   */
  @Input()
  id: number;

  /**
   *  Label do componente.
   */
  @Input()
  label: string;

  /**
   * Atributo name do componente.
   */
  @Input()
  name = '';

  /**
   *  Valor do componente.
   */
  @Input()
  value: string | number;

  /**
   * AtivaÃ§Ã£o do radio button.
   */
  @Input()
  checked = false;

  /**
   * Desabilitar o componenente.
   */
  @Input()
  disabled = false;

  /**
   * Utilizando "true", permite que a primeira letra da label seja maiÃºscula
   */
  @Input()
  firstLetterUppercase = false;

  /**
   * Evento disparado ao radio detectar uma mudanÃ§a
   */
  @Output()
  radioChange = new EventEmitter<IRadioButtonEvent>();

  /**
   * Evento disparado ao clicar na opÃ§Ã£o desejada
   */
  @Output()
  radioClick = new EventEmitter<IRadioButtonEvent>();

  /**
   * @internal
   */
  segment: Observable<SegmentTypes> = this.segmentService.segment;

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
    private segmentService: VoxelSegmentService,
  ) { }

  /**
   * @internal
   */
  ngOnChanges() {
    this.validateParam.validatePropertyOnObject(
      this,
      VoxelRadioButtonComponent.selector,
      ['name', 'value', 'id'],
    );
  }

  /**
   * @internal
   */
  onRadioClick($event: Event) {
    this.radioClick.emit(this.createEmit($event));
  }

  /**
   * @internal
   */
  onRadioChange($event: Event) {
    this.radioChange.emit(this.createEmit($event));
  }

  /**
   * @internal
   */
  private createEmit($event: Event): IRadioButtonEvent {
    const target: any = $event.target;
    this.checked = target.checked;

    return {
      $event,
      id: this.id,
      label: this.label,
      checked: target.checked,
      disabled: this.disabled,
      name: this.name,
      value: this.value,
      valid: target.validity ? target.validity.valid : false,
    };
  }

}
