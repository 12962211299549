import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

export interface ICustomMaskEvent {
  event: Event;
  value: string;
  cleanValue: string;
}

/**
 * Diretiva para utilizaÃ§Ã£o de mascaras genÃ©ricas em inputs
 *
 * @example <input voxelCustomMask [textMask]="minhaMascara" (customMaskValue)="customMaskValueOutput($event)">
 */
@Directive({
  selector: '[voxelCustomMask]',
})
export class VoxelCustomMaskDirective {

  /**
   * MÃ¡scara customizada
   */
  @Input()
  textMask = '';

  /**
   * Tipo da mÃ¡scara configurada
   */
  @Input()
  maskTypeGeneric = '';

  /**
   * Operadores para mÃ¡scara
   */
  @Input()
  maskOperators?: any;

  /**
   * Evento disparado no a cada input de dado
   */
  @Output()
  customMaskValue = new EventEmitter<ICustomMaskEvent>();

  private regexTypes = {
    alphaNumeric : /[a-zA-Z0-9]/g,
    notAlphaNumeric: /([^a-zA-Z0-9]+)/g,
  };

  private maskedValue = '';

  private cleanValue = '';

  private maskType = 'custom-mask';

  @HostListener('input', ['$event'])
  onKeyUp($event: Event) {
    if (this.dontApplyMask()) {
      return '';
    }
    const inputHtmlElement = $event.target as HTMLInputElement;
    const value = inputHtmlElement.value;
    this.maskedValue = this.applyCustomMask(value, this.textMask);
    this.cleanValue = this.clearValue(this.maskedValue);

    this.updateValues($event);
  }

  /**
   * @internal
   */
  dontApplyMask() {
    return this.maskTypeGeneric !== this.maskType;
  }

  /**
   * @internal
   */
  applyCustomMask(value: string, mask: string) {

    const cleanValue = this.clearValue(value);
    let cleanValueIndex = 0;
    let maskChar;
    let valueChar;
    let regex;
    let maskedValue = '';

    for (let maskIndex = 0; maskIndex < mask.length; maskIndex++) {

      if (cleanValueIndex === cleanValue.length) {
        return maskedValue;
      }

      maskChar = mask.charAt(maskIndex);
      valueChar = cleanValue.charAt(cleanValueIndex);
      regex = this.maskOperators[maskChar];

      if (!regex) {
        maskedValue += maskChar;
        continue;
      }

      if (valueChar.match(regex)) {
        maskedValue += valueChar;
        cleanValueIndex++;
        continue;
      }

      break;
    }

    return maskedValue.match(this.regexTypes['alphaNumeric']) ? maskedValue : '';
  }

  /**
   * @internal
   */
  clearValue(value: string) {
    return value.replace(this.regexTypes['notAlphaNumeric'], '');
  }

  /**
   * @internal
   */
  updateValues($event: Event) {
    const inputHtmlElement = $event.target as HTMLInputElement;
    inputHtmlElement.value = this.maskedValue;
    this.customMaskValue.emit({
      event: $event,
      value: this.maskedValue,
      cleanValue: this.cleanValue,
    });
  }
}
