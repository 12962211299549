import { Component, HostBinding, Input, OnChanges } from '@angular/core';

import { VoxelValidateParameterService } from './../../services/validate-parameter/validate-parameter.service';

/**
 * Componente Grid Item (parte do {@link ../additional-documentation/--guia-de-desenvolvimento.html Sistema de Grid do Voxel})
 *
 * Esse componente Ã© utilizado dentro do componente {@link VoxelGridContainerComponent} ou
 * {@link VoxelGridRowComponent} para criar as colunas (itens) de um grid.
 *
 * <example-url>../../demo/index.html#/portfolio/grid?componentOnly=true</example-url>
 *
 * @example
 * <voxel-container>
 *   <voxel-row>
 *     <voxel-item dc="6" tc="6" mc="12"></voxel-item>
 *   <voxel-row>
 * </voxel-container>
 */
@Component({
  selector: 'voxel-item',
  styleUrls: ['./grid.component.scss'],
  template: `
    <div>
      <ng-content></ng-content>
    </div>
  `,
})
export class VoxelGridItemComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-item';

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
  ) {}

  /**
   * (desktop columns)
   * NÃºmero de colunas para desktop em um grid de 12 colunas entre 1200px e 1024px
   */
  @Input() dc: number;

  /**
   * (tablet columns)
   * NÃºmero de colunas para tablet em um grid de 12 colunas entre 1024px e 768px
   */
  @Input() tc: number;

  /**
   * (mobile columns)
   * NÃºmero de colunas para mobile em um grid de 12 colunas abaixo de 768px
   */
  @Input() mc: number;

  /**
   * @internal
   */
  @HostBinding('class') itemClasses: string[] = [];

  /**
   * @internal
   */
  ngOnChanges() {
    this.validateParam.validatePropertyOnObject(this, VoxelGridItemComponent.selector, [
      'dc',
      'tc',
      'mc',
    ]);
    this.itemClasses = [`voxel-grid__item voxel-grid__item--lg-${this.dc} voxel-grid__item--md-${this.tc} voxel-grid__item--sm-${this.mc}`];
  }

}
