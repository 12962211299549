import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelValidateParameterModule } from './../../services/validate-parameter/validate-parameter.module';
import { VoxelGridContainerComponent } from './container.component';
import { VoxelGridItemComponent } from './item.component';
import { VoxelGridRowComponent } from './row.component';

export * from './container.component';
export * from './item.component';
export * from './row.component';

@NgModule({
  imports: [
    CommonModule,
    VoxelValidateParameterModule,
  ],
  declarations: [
    VoxelGridContainerComponent,
    VoxelGridRowComponent,
    VoxelGridItemComponent,
  ],
  exports: [
    VoxelGridContainerComponent,
    VoxelGridRowComponent,
    VoxelGridItemComponent,
  ],
  providers: [
  ],
})
export class VoxelGridModule {}
