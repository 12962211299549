import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { VoxelAutoTabDirectiveModule } from './../../directives/auto-tab/auto-tab.module';
import { VoxelCpfCnpjDirectiveModule } from './../../directives/cpf-cnpj/cpf-cnpj.module';
import { VoxelCurrencyFormatterDirectiveModule } from './../../directives/currency-formatter/currency-formatter.module';
import { VoxelCustomMaskDirectiveModule } from './../../directives/custom-mask/custom-mask.module';
import { VoxelDateDirectiveModule } from './../../directives/date/date.module';
import { VoxelMaxlengthFormatterDirectiveModule } from './../../directives/maxlength-formatter/maxlength-formatter.module';
import { VoxelCpfCnpjValidatorService } from './../../services/cpf-cnpj-validator/cpf-cnpj-validator.service';
import { VoxelCustomMaskValidatorService } from './../../services/custom-mask-validator/custom-mask-validator.service';
import { VoxelDateValidatorService } from './../../services/date-validator/date-validator.service';
import { VoxelNumberFormatterService } from './../../services/number-formatter/number-formatter.service';
import { VoxelValidateParameterModule } from './../../services/validate-parameter/validate-parameter.module';
import { VoxelSegmentModule } from './../../services/segment/segment.module';
import { VoxelInputComponent } from './input.component';
import { VoxelIconModule } from '../icon/icon.module';

export * from './input.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    VoxelCurrencyFormatterDirectiveModule,
    VoxelMaxlengthFormatterDirectiveModule,
    VoxelCpfCnpjDirectiveModule,
    VoxelDateDirectiveModule,
    VoxelCustomMaskDirectiveModule,
    VoxelAutoTabDirectiveModule,
    VoxelValidateParameterModule,
    VoxelSegmentModule,
    VoxelIconModule,
  ],
  declarations: [
    VoxelInputComponent,
  ],
  providers: [
    VoxelCpfCnpjValidatorService,
    VoxelDateValidatorService,
    VoxelCustomMaskValidatorService,
    VoxelNumberFormatterService,
  ],
  exports: [ VoxelInputComponent ],
})
export class VoxelInputModule { }
