import { NgModule } from '@angular/core';

import { VoxelNumberFormatterService } from './number-formatter.service';

@NgModule({
  providers: [VoxelNumberFormatterService],
})
export class VoxelNumberFormatterServiceModule { }

export * from './number-formatter.service';
