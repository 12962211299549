import { Injectable } from '@angular/core';

/**
 * ServiÃ§o para validaÃ§Ã£o de CPF e CNPJ
 */
@Injectable()
export class VoxelCpfCnpjValidatorService {

  /**
   * ValidaÃ§Ã£o de CPF e CNPJ ao mesmo tempo
   *
   * @param value valor a ser validado
   * @returns Resultado da validaÃ§Ã£o
   */
  validateCpfCnpj(value: string): boolean {
    return value.length > 11 ? this.validateCnpj(value) : this.validateCpf(value);
  }

  /**
   * ValidaÃ§Ã£o de CNPJ
   *
   * @param cnpj valor a ser validado
   * @returns Resultado da validaÃ§Ã£o
   */
  validateCnpj(cnpj: string): boolean {
    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let soma = 0;
    let pos = length - 7;
    let result;
    const digits = cnpj.substring(length);

    if (cnpj === '' || cnpj === undefined) {
      return false;
    }

    if (cnpj.length !== 14) {
      return false;
    }

    if (cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    ) {
      return false;
    }

    for (let i = length; i >= 1; i--) {
      soma += +numbers.charAt(length - i) * pos--;

      if (pos < 2) {
        pos = 9;
      }
    }

    result = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (result !== parseInt(digits.charAt(0), 10)) {
      return false;
    }

    length = length + 1;
    numbers = cnpj.substring(0, length);
    soma = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
      soma += +numbers.charAt(length - i) * pos--;

      if (pos < 2) {
        pos = 9;
      }
    }

    result = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (result !== parseInt(digits.charAt(1), 10)) {
      return false;
    }

    return true;
  }

  /**
   * ValidaÃ§Ã£o de CPF
   *
   * @param cpf valor a ser validado
   * @returns Resultado da validaÃ§Ã£o
   */
  validateCpf(cpf: string): boolean {
    let sum = 0;
    let rest;

    if (cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }

    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) {
      rest = 0;
    }

    if (rest !== parseInt(cpf.substring(9, 10), 10)) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }

    rest = (sum * 10) % 11;

    if ((rest === 10) || (rest === 11)) {
      rest = 0;
    }

    if (rest !== parseInt(cpf.substring(10, 11), 10)) {
      return false;
    }

    return true;
  }
}
