import { Component } from '@angular/core';

/**
 * Componente Grid Container (parte do {@link ../additional-documentation/--guia-de-desenvolvimento.html Sistema de Grid do Voxel})
 *
 * Esse componente Ã© usado para criar uma `<div>` que define a largura mÃ¡xima do conteÃºdo e o centraliza, jÃ¡ aplicando o padding padrÃ£o.
 *
 * <example-url>../../demo/index.html#/portfolio/grid?componentOnly=true</example-url>
 *
 * @example
 * <voxel-container>
 *   <voxel-row>
 *     <voxel-item dc="6" tc="6" mc="12"></voxel-item>
 *   <voxel-row>
 * </voxel-container>
 */

@Component({
  selector: 'voxel-container',
  styleUrls: ['./grid.component.scss'],
  template: `
  <div class="voxel-grid__container">
    <ng-content></ng-content>
  </div>
  `,
})
export class VoxelGridContainerComponent {

  /**
   * @internal
   */
  static selector = 'voxel-container';

}
