import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VoxelTabsComponent } from './tabs.component';
import { VoxelNavigationModule } from '../navigation/navigation.module';
import { VoxelValidateParameterModule } from './../../services/validate-parameter/validate-parameter.module';

export * from './tabs.component';

@NgModule({
  imports: [
    CommonModule,
    VoxelNavigationModule,
    VoxelValidateParameterModule,
  ],
  providers: [
  ],
  declarations: [
    VoxelTabsComponent,
  ],
  exports: [
    VoxelTabsComponent,
  ],
})

export class VoxelTabsModule { }
