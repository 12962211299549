import { ValidateParameterNotifierService } from '../../services/validate-parameter/validate-parameter-notifier.service';
import { Component, Input, OnChanges } from '@angular/core';

interface IIndexString {
  [index: string]: string;
}

export type IStampType = 'support' | 'tip' | 'warning' | 'reminder' | 'error';

/**
 * Componente de Stamp
 *
 * Stamp sÃ£o selos, que permitem destacar informaÃ§Ãµes relevantes para o usuÃ¡rio.
 * O componente Ã© estÃ¡tico e nÃ£o interativo.
 *
 * <example-url>../../demo/index.html#/portfolio/stamp?componentOnly=true</example-url>
 *
 * @example Dica
 * <voxel-stamp text="lorem ipsum"></voxel-stamp>
 *
 * @example Apoio
 * <voxel-stamp
 *  text="lorem ipsum"
 *  type="support">
 * </voxel-stamp>
 *
 * @example Aviso
 * <voxel-stamp
 *  text="lorem ipsum"
 *  type="warning">
 * </voxel-stamp>
 *
 * @example Lembrete
 * <voxel-stamp
 *  text="lorem ipsum"
 *  type="reminder">
 * </voxel-stamp>
 *
 * @example Erro
 * <voxel-stamp
 *  text="lorem ipsum"
 *  type="error">
 * </voxel-stamp>
 */
@Component({
  selector: 'voxel-stamp',
  templateUrl: './stamp.component.html',
  styleUrls: ['./stamp.component.scss'],
})
export class VoxelStampComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-stamp';

  /**
   * Texto a ser exibido
   */
  @Input()
  text = '';

  /**
   * Tipo do selo
   */
  @Input()
  type: IStampType = 'tip';

  /**
   * @internal
   */
  types: IIndexString = {
    support: 'Apoio',
    tip: 'Dica',
    reminder: 'Lembrete',
    warning: 'Aviso',
    error: 'Erro',
  };

  constructor(
    private validationNotifier: ValidateParameterNotifierService,
  ) {}

  /**
   * @internal
   */
  ngOnChanges() {
    if (this.text.length > 25) {
      this.text = '';
      this.validationNotifier.notify('property text must have a maximum of 25 characters');
    }
  }
}
