import { NgModule } from '@angular/core';
import { SegmentTypeDefaultProvider } from './segment.interface';

@NgModule({
  providers: [
    SegmentTypeDefaultProvider,
  ],
})
export class VoxelSegmentModule { }

export * from './segment.service';
export { SegmentTypes } from './segment.interface';
