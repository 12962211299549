import { Inject, Injectable } from '@angular/core';

import { ValidateParameterNotificationTypeDefault, ValidateParameterNotificationTypes } from './validate-parameter-notifier.interface';

/**
 * @internal
 */
@Injectable()
export class ValidateParameterNotifierService {

  constructor(
    @Inject(ValidateParameterNotificationTypeDefault) private notificationType: ValidateParameterNotificationTypes,
  ) {}

  notify(error: string) {
    switch (this.notificationType) {
      case ValidateParameterNotificationTypes.ThrowError:
        throw new Error(error);
      case ValidateParameterNotificationTypes.ConsoleLog:
        console.error(error);
        break;
      default:
        break;
    }
  }
}
