import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';

import { SegmentTypes, VoxelSegmentService } from '../../services/segment/segment.module';
import { VoxelValidateParameterService } from '../../services/validate-parameter/validate-parameter.module';

/**
 * Classe de referÃªncia para objetos do option
 */
export interface IOption {

  /**
   * Valor do objeto de option
   */
  value: string;
  /**
   * Texto que sera exibido no option
   */
  label: string;
  /**
   * Indicador se objeto estÃ¡ selecionado
   */
  selected?: boolean;
}

/**
 *  @internal
 *  Componente base para select, dropdown e autocomplete
 */
@Component({
  selector: 'voxel-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss'],
})
export class VoxelOptionsComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-options';

  /**
   * @internal
   * Elemento de lista
   */
  @ViewChild('listRef') listElement: ElementRef;

  /**
   * @internal
   * Elementos de itens da lista
   */
  @ViewChildren('optionRef') items: QueryList<ElementRef>;

  /**
   * ID da lista de items
   */
  @Input()
  id: string;

  /**
   * IndicaÃ§Ã£o se lista estÃ¡ visÃ­vel
   */
  @Input()
  isVisible = false;

  /**
   * Lista de items (option) da lista
   */
  @Input()
  options: IOption[] = [];

  /**
   * Ãndice do elemento ativo selecionado por teclado
   */
  @Input()
  set indexActive(index: number) {
    this.currentIndex = index;
    if (! this.items) { return; }
    this.scrollToItem(this.items.toArray()[index]);
  }

  /**
   * Evento disparado sempre quando um item Ã© selecionado
   */
  @Output()
  changeValue = new EventEmitter<IOption>();

  /**
   * @internal
   */
  segment: Observable<SegmentTypes> = this.segmentService.segment;

  /**
   * @internal
   */
  currentIndex: number;

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
    private segmentService: VoxelSegmentService,
  ) { }

  ngOnChanges() {
    this.validateParam.validatePropertyOnObject(
      this,
      VoxelOptionsComponent.selector,
      ['id'],
    );
  }

  /**
   * @internal
   */
  onChangeValue(option: IOption, index: number) {
    this.cleanSelected();
    if (option.value !== '') {
      this.options[index].selected = true;
    }

    this.changeValue.emit(option);
  }

  /**
   * @internal
   */
  cleanSelected() {
    this.options.forEach(option => option.selected = false);
  }

  /**
   * @internal
   */
  getOptionSelectedId() {
    const selectedOption = this.options.filter(opt => opt.selected === true);
    if (selectedOption.length > 0) {
      return `${this.id}-${this.options.indexOf(selectedOption[0])}`;
    }
    return null;
  }

  /**
   * @internal
   */
  scrollToItem(optionRef: ElementRef) {
    if (!optionRef) { return; }
    this.listElement.nativeElement.scrollTop = optionRef.nativeElement.offsetTop;
  }
}
