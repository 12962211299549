import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

export interface IMaxlengthFormatterEvent {
  value: string;
}

/**
 * Diretiva para limitar tamanho de entrada de texto em inputs
 *
 * @example <input voxelMaxlengthFormatter [maxlengthFormatter]="2">
 */
@Directive({
  selector: '[voxelMaxlengthFormatter]',
})
export class VoxelMaxlengthFormatterDirective {

  /**
   * Quantidade mÃ¡xima de caracteres permitidos no componente
   */
  @Input()
  maxlengthFormatter?: number;

  /**
   * Evento de formataÃ§Ã£o
   */
  @Output()
  maxlengthFormatterChange = new EventEmitter<IMaxlengthFormatterEvent>();

  /**
   * @internal
   */
  constructor(
    private element: ElementRef,
  ) {}

  @HostListener('input')
  onEvent() {
    if (!this.maxlengthFormatter) {
      return;
    }
    this.maxlengthFormatterChange.emit({
      value: this.element.nativeElement.value.substring(0, this.maxlengthFormatter),
    });
  }
}
