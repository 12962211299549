import { NgModule } from '@angular/core';

import { VoxelCustomMaskValidatorService } from './custom-mask-validator.service';

@NgModule({
  providers: [VoxelCustomMaskValidatorService],
})
export class VoxelCustomMaskValidatorServiceModule { }

export * from './custom-mask-validator.service';
