import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelValidateParameterModule } from './../../services/validate-parameter/validate-parameter.module';
import { VoxelSegmentModule } from './../../services/segment/segment.module';
import { VoxelCheckboxComponent } from './checkbox.component';
import { VoxelIconModule } from '../icon/icon.module';

export * from './checkbox.component';

@NgModule({
  imports: [
    CommonModule,
    VoxelValidateParameterModule,
    VoxelSegmentModule,
    VoxelIconModule,
  ],
  providers: [
  ],
  declarations: [VoxelCheckboxComponent],
  exports: [VoxelCheckboxComponent],
})
export class VoxelCheckboxModule { }
