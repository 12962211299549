import { NgModule } from '@angular/core';

import { ValidateParameterNotifierService } from './validate-parameter-notifier.service';
import { ValidateParameterNotificationTypeDefaultProvider } from './validate-parameter-notifier.interface';
import { VoxelValidateParameterService } from './validate-parameter.service';

@NgModule({
  providers: [
    ValidateParameterNotificationTypeDefaultProvider,
    ValidateParameterNotifierService,
    VoxelValidateParameterService,
  ],
})
export class VoxelValidateParameterModule { }

export * from './validate-parameter.service';
