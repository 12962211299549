import { ValidateParameterNotifierService } from './validate-parameter-notifier.service';
import { Injectable } from '@angular/core';

/**
 * @internal
 */
@Injectable()
export class VoxelValidateParameterService {

  constructor(
    private notificationErrorService: ValidateParameterNotifierService,
  ) {}

  validateEmptyString(parameter: string | undefined, component: string, name: string) {
    if (parameter === '') {
      this.notificationErrorService.notify(`O parÃ¢metro ${name} do componente ${component} Ã© obrigatÃ³rio e estÃ¡ vazio.`);
    }
  }

  validateUndefined(parameter: any, component: string, name: string) {
    if (parameter === undefined) {
      this.notificationErrorService.notify(`O parÃ¢metro ${name} do componente ${component} Ã© obrigatÃ³rio e estÃ¡ undefined.`);
    }
  }

  validateUndefinedOrEmptyString(parameter: any, component: string, name: string) {
    if (parameter === undefined || parameter === '') {
      this.notificationErrorService.notify(`O parÃ¢metro ${name} do componente ${component} Ã© obrigatÃ³rio e estÃ¡ vazio ou undefined.`);
    }
  }

  validateUndefinedOrEmptyArray(parameter: any, component: string, name: string) {
    if (parameter === undefined || parameter.length === 0) {
      this.notificationErrorService.notify(`O parÃ¢metro ${name} do componente ${component} Ã© obrigatÃ³rio e estÃ¡ vazio ou undefined.`);
    }
  }

  validateUndefinedOrNumber(parameter: any, component: string, name: string) {
    if (isNaN(parameter) || typeof parameter !== 'number') {
      this.notificationErrorService.notify(
        `O parÃ¢metro ${name} do componente ${component} Ã© obrigatÃ³rio e estÃ¡ undefined ou nÃ£o Ã© do tipo number.`);
    }
  }

  validateBoolean(parameter: any, component: string, name: string) {
    if (!parameter) {
      this.notificationErrorService.notify(`O parÃ¢metro ${name} do componente ${component} Ã© obrigatÃ³rio.`);
    }
  }

  validateFirstSmallerThanSecond(parameters: number[], component: string, names: string[]) {

    if (parameters.length !== 2) {
      this.notificationErrorService.notify(`A validaÃ§Ã£o de parÃ¢metros deve conter 2 elementos no array.`);
    }

    if (parameters[0] > parameters[1]) {
      this.notificationErrorService.notify(
        `O parÃ¢metro "${ names[0] }" deve ser menor do que "${ names[1] }" para o componente ${component}.`);
    }
  }

  validateMaxLength(parameter: any, length: number, component: string, name: string) {
    const parameterLength = typeof parameter === 'number' ? parameter : parameter.length;

    // tslint:disable-next-line:early-exit
    if (parameterLength > length) {
      this.notificationErrorService.notify(
        `O parÃ¢metro "${ name }" deve ter o tamanho mÃ¡ximo de ${length} opÃ§Ãµes para o componente ${component}.`,
      );
    }
  }

  validateMinLength(parameter: any, length: number, component: string, name: string) {
    const parameterLength = typeof parameter === 'number' ? parameter : parameter.length;

    // tslint:disable-next-line:early-exit
    if (parameterLength < length) {
      this.notificationErrorService.notify(
        `O parÃ¢metro "${ name }" deve ter tamanho mÃ­nimo de ${length} opÃ§Ãµes para o componente ${component}.`,
      );
    }
  }

  validateTypes(parameter: any, component: string, name: string, types: string[]) {
    const matchTypes = types.filter(type => {
      return typeof parameter === type;
    });

    if (matchTypes.length === 0) {
      this.notificationErrorService.notify(
        `O parÃ¢metro ${name} do componente ${component} nÃ£o sÃ£o dos seguintes tipos: ${types.toString()}.`);
    }
  }

  validateIfExistParameterTypeInTheTypesArray(parameter: any, component: string, name: string, types: string[]) {
    const matchTypes = types.filter(type => {
      return typeof parameter === type;
    });

    if (matchTypes.length === 0) {
      this.notificationErrorService.notify(
        `O parÃ¢metro ${name} do componente ${component} nÃ£o sÃ£o dos seguintes tipos: ${ types.toString() }.`);
    }
  }

  validateDate(parameter: any, component: string, name: string) {
    if (typeof parameter !== 'object' || (parameter instanceof Date) === false) {
      this.notificationErrorService.notify(`O parÃ¢metro "${ name }" deve ser do tipo "Data" para o componente ${component}.`);
    }
  }

  validatePropertyOnObjectArray(parameter: any[], component: string, property: any, name = '') {
    parameter.map(param => {
      this.validatePropertyOnObject(param, component, (typeof property === 'string' ? [property] : property), name);
    });
  }

  validatePropertyOnObject(parameter: any, component: string, propertys: string[], name = '') {
    const propertyErros = propertys.reduce((propers, property) => {

      const properConditional = property[property.length - 1] === '?';
      const nameProper = property.substring(0, properConditional ? property.length - 1 : property.length);
      const valueParam = parameter[nameProper];

      if (properConditional) {
        if (valueParam === '') {
          propers.push(nameProper);
        }
        return propers;
      }

      if (!valueParam || valueParam === '' || valueParam === undefined) {
        propers.push(nameProper);
      }
      return propers;
    }, [] as any[]);

    if (propertyErros.length > 0) {
      this.notificationErrorService.notify(
        `A(s) propriedade(s) [${propertyErros.join(', ')}]${name ? ' do parÃ¢metro ' + name : ''} do componente ${component} estÃ¡ vazio.`);
    }
  }
  validateCoexist(parameters: any[], component: string, names: string) {
    const checked = parameters.reduce((sum, param) => !param ? sum : sum + 1, 0);

    if (parameters.length === checked) {
      this.notificationErrorService.notify(
        `Os parÃ¢metros "${ names }" nÃ£o devem coexistir, defina somente um dos parÃ¢metros para o componente ${component}.`);
    }
  }

  validateExistAtLeastOne(parameters: any[], component: string, names: string) {
    if (!parameters.some(param => param)) {
      this.notificationErrorService.notify(`Um dos parÃ¢metros "${ names }" devem existir para o componente ${component}.`);
    }
  }

  validateNumberOfDigits(parameters: any[], number: number, component: string, names: string) {
    parameters.map(param => {
      if (param.value.toString().length > number) {
        this.notificationErrorService.notify(
          `Um dos parÃ¢metros "${ names }" ultrapassa o limite de ${ number } digitos permitido para o componente ${ component }`);
      }
    });
  }
}
