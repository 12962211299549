import { ViewportScroller } from '@angular/common';
import { AfterViewChecked, Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Diretiva para ajustar altura de um elemento conforme interaÃ§Ã£o do usuÃ¡rio
 *
 * @example <textarea voxelAutoHeight></textarea>
 */
@Directive({
  selector: '[voxelAutoHeight]',
})
export class VoxelAutoHeightDirective implements AfterViewChecked {

  /**
   * @internal
   */
  constructor(
    private element: ElementRef,
    private viewportScroller: ViewportScroller,
  ) {}

  /**
   * @internal
   */
  ngAfterViewChecked() {
    this.adjustSize();
  }

  @HostListener('input')
  adjustSize() {
    const element = this.element.nativeElement;
    const position = this.viewportScroller.getScrollPosition();
    element.style.overflow = 'hidden';
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + 'px';

    this.viewportScroller.scrollToPosition(position);
  }
}
