import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { SegmentTypes, VoxelSegmentService } from './../../services/segment/segment.module';
import { VoxelValidateParameterService } from './../../services/validate-parameter/validate-parameter.service';

/**
 * Dados dos eventos do checkbox
 */
export interface ICheckboxEvent {
  /**
   *  Evento nativo de checked
   */
  $event: Event;
  /**
   *  IdentificaÃ§Ã£o Ãºnica do input de checkbox
   */
  id: string;
  /**
   *  Label usado no input de checkbox
   */
  label: string;
  /**
   *  Nome usado no input de checkbox
   */
  name: string;
  /**
   *  AtivaÃ§Ã£o do checkbox
   */
  checked: boolean;
  /**
   *  AtivaÃ§Ã£o do sub selections
   */
  subselections: boolean;
  /**
   *  Desabilitar o componenente
   */
  disabled: boolean;
  /**
   *  Valor do checkbox no momento do evento
   */
  value: string | number;
  /**
   *  VerificaÃ§Ã£o se o checkbox Ã© obrigatÃ³rio
   */
  required: boolean;
  /**
   *  VerificaÃ§Ã£o se o checkbox Ã© estÃ¡ vÃ¡lido
   */
  valid: boolean;
}

/**
 *  Componente Checkbox
 *
 *  Executa a funÃ§Ã£o literal nomeada, por padrÃ£o Ã© renderizado como caixas marcadas quando ativas.
 *  AlÃ©m de checagens, pode ser utilizado em validaÃ§Ãµes, afirmaÃ§Ãµes e como start para outras aÃ§Ãµes.
 *  Permite valores como ativo ou desativado.
 *  Permite tambÃ©m ser acompanhado ou nÃ£o por um label.
 *
 *  <example-url>../../demo/index.html#/portfolio/checkbox?componentOnly=true</example-url>
 *
 *  @example Ativo
 *  <voxel-checkbox
 *    [label]="'varejo'"
 *    [name]="'accountType'"
 *    [id]="'varejo'"
 *    value="1">
 *  </voxel-checkbox>
 *
 *  @example Inativo
 *  <voxel-checkbox
 *    [label]="'uniclass'"
 *    [required]="true"
 *    [name]="'accountType'"
 *    [id]="'uniclass'"
 *    [checked]="true"
 *    value="2">
 *  </voxel-checkbox>
 *
 *  @example Desabilitado
 *  <voxel-checkbox
 *    [label]="'personnalite'"
 *    [disabled]="true"
 *    [name]="'accountType'"
 *    [id]="'personnalite'"
 *    value="3">
 *  </voxel-checkbox>
 *
 *  @example Selecionado sem label
 *  <voxel-checkbox
 *    [required]="true"
 *    [name]="'accountType'"
 *    [id]="'sem nome'"
 *    [checked]="true"
 *    value="2">
 *  </voxel-checkbox>
 */
@Component({
  selector: 'voxel-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class VoxelCheckboxComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-checkbox';

  /**
   *  Label usado no input de checkbox
   */
  @Input()
  label = '';

  /**
   * IdentificaÃ§Ã£o Ãºnica do input de checkbox
   */
  @Input()
  id: string;

  /**
   * Nome usado no input de checkbox
   */
  @Input()
  name: string;

  /**
   *  Valor do componente
   */
  @Input()
  value: string | number;

  /**
   * AtivaÃ§Ã£o do checkbox
   */
  @Input()
  checked = false;

  /**
   * AtivaÃ§Ã£o do sub selections
   */
  @Input()
  subselections = false;

  /**
   * Desabilitar o componenente
   */
  @Input()
  disabled = false;

  /**
   * VerificaÃ§Ã£o se o checkbox Ã© obrigatÃ³rio
   */
  @Input()
  required = false;

  /**
   * Evento disparado ao clicar a opÃ§Ã£o selecionada
   */
  @Output()
  checkboxChange = new EventEmitter<ICheckboxEvent>();

  /**
   * Evento disparado quando o evento focus Ã© disparado.
   */
  @Output()
  checkboxFocus = new EventEmitter<ICheckboxEvent>();

  /**
   * Evento disparado quando o evento blur Ã© disparado.
   */
  @Output()
  checkboxBlur = new EventEmitter<ICheckboxEvent>();

  /**
   * @internal
   */
  labelDisabled = '';

  /**
   * @internal
   */
  segment: Observable<SegmentTypes> = this.segmentService.segment;

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
    private segmentService: VoxelSegmentService,
  ) { }

  /**
   * @internal
   */
  ngOnChanges() {
    this.validateParam.validatePropertyOnObject(
      this, VoxelCheckboxComponent.selector, [
        'name',
        'value',
        'id',
      ]);

    this.labelDisabled = this.disabled && this.label
      ? `${this.label}, item desativado` : '';
  }

  /**
   * @internal
   */
  onChange($event: Event) {
    this.checkboxChange.emit(this.createEmit($event));
  }

  /**
   * @internal
   */
  onBlur($event: Event) {
    this.checkboxBlur.emit(this.createEmit($event));
  }

  /**
   * @internal
   */
  onFocus($event: Event) {
    this.checkboxFocus.emit(this.createEmit($event));
  }

  /**
   * @internal
   */
  createEmit($event: Event): ICheckboxEvent {
    const target: any = $event.target;
    this.checked = target.checked;

    return {
      $event,
      id: this.id,
      label: this.label,
      name: this.name,
      checked: this.checked,
      subselections: this.subselections,
      disabled: this.disabled,
      value: this.value,
      required: this.required,
      valid: target.validity ? target.validity.valid : false,
    };
  }
}
