import { NgModule } from '@angular/core';

import { VoxelValidateParameterModule } from '../../services/validate-parameter/validate-parameter.module';
import { VoxelStampComponent } from './stamp.component';

export * from './stamp.component';

@NgModule({
  imports: [
    VoxelValidateParameterModule,
  ],
  declarations: [VoxelStampComponent],
  exports: [VoxelStampComponent],
})
export class VoxelStampModule { }
