import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelValidateParameterService } from '../../services/validate-parameter/validate-parameter.service';
import { VoxelStepperComponent } from './stepper.component';
import { VoxelStepComponent } from './step/step.component';
import { VoxelStepperNavigationDirective } from './stepper.directive';
import { VoxelGridModule } from '../grid/grid.module';
import { VoxelLinkModule } from '../link/link.module';
import { VoxelValidateParameterModule } from './../../services/validate-parameter/validate-parameter.module';
import { VoxelIconModule } from '../icon/icon.module';

export * from './stepper.component';
export * from './stepper.directive';

@NgModule({
  imports: [
    CommonModule,
    VoxelGridModule,
    VoxelLinkModule,
    VoxelValidateParameterModule,
    VoxelIconModule,
  ],
  providers: [
    VoxelValidateParameterService,
  ],
  declarations: [
    VoxelStepperComponent,
    VoxelStepComponent,
    VoxelStepperNavigationDirective,
  ],
  exports: [
    VoxelStepperComponent,
    VoxelStepComponent,
    VoxelStepperNavigationDirective,
  ],
})

export class VoxelStepperModule { }
