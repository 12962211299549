import { Directive, ElementRef, AfterContentChecked } from '@angular/core';
import { ValidateParameterNotifierService } from '../../services/validate-parameter/validate-parameter-notifier.service';

/**
 * Diretiva para validaÃ§Ã£o do conteÃºdo de um elemento HTML
 *
 * @example
 * <span voxelValidateContent> content </span>
 *
 */
@Directive({
  selector: '[voxelValidateContent]',
})
export class VoxelValidateContentDirective implements AfterContentChecked {

  /**
   * @internal
   */
  static errorMessage = 'O conteÃºdo do elemento esta vazio e Ã© obrigatÃ³rio';

  /**
   * @internal
   */
  lastContent: string;

  constructor(
    private element: ElementRef,
    private notificationErrorService: ValidateParameterNotifierService,
  ) {}

  ngAfterContentChecked() {
    this.validate(this.element.nativeElement.innerHTML);
  }

  /**
   * @internal
   */
  validate(currentContent: string) {
    if (currentContent === this.lastContent) {
      return;
    }
    this.lastContent = currentContent;
    const contentValidate = this.lastContent || '';
    if (contentValidate.trim() === '') {
      this.notificationErrorService.notify(VoxelValidateContentDirective.errorMessage);
    }
  }
}
