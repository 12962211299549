import { Component, Input, OnChanges } from '@angular/core';

import { SegmentTypes, VoxelSegmentService } from './../../services/segment/segment.module';
import { Observable } from 'rxjs';
import { VoxelValidateParameterService } from '../../services/validate-parameter/validate-parameter.service';

/**
 *
 * Componente accordion
 *
 * Componente de interface que permite ao usuÃ¡rio expandir e colapsar sessÃµes de conteÃºdo
 *
 * <example-url>../../demo/index.html#/portfolio/accordion?componentOnly=true</example-url>
 *
 * @example Normal
 * <voxel-accordion
 *   title="accordion">
 *   conteÃºdo
 * </voxel-accordion>
 */

@Component({
  selector: 'voxel-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: [ './accordion.component.scss'],
})
export class VoxelAccordionComponent implements OnChanges {

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
    private segmentService: VoxelSegmentService,
  ) { }

  /**
   * @internal
   */
  static selector = 'voxel-accordion';

  /**
   * @internal
   */
  segment: Observable<SegmentTypes> = this.segmentService.segment;

  /**
   * @internal
   */
  id = Math.random().toString(36).substr(2, 9);

  /**
   * TÃ­tulo do accordion
   */
  @Input()
  title: string;

  /**
   * @internal
   */
  isCollapsed = true;

  /**
   * @internal
   */
  ngOnChanges() {
    this.validateParam.validatePropertyOnObject(
      this,
      VoxelAccordionComponent.selector,
      ['title'],
    );
  }

  /**
   * @internal
   */
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
}
