import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { SegmentTypes, VoxelSegmentService } from '../../services/segment/segment.module';
import { VoxelValidateParameterService } from '../../services/validate-parameter/validate-parameter.module';
import { IOption } from '../options/options.component';

/**
 * Select Ã© um componente usado para que o usuÃ¡rio possa selecionar uma opÃ§Ã£o de uma lista.
 *
 * <example-url>../../demo/index.html#/portfolio/select?componentOnly=true</example-url>
 *
 * @example objetos para propriedade de options
 *
 * options = [
 *    { value: 'label1', label: 'Label 1' },
 *    { value: 'label2', label: 'Label 2' },
 *    { value: 'label3', label: 'Label 3' },
 *  ];
 *
 * @example simples
 * <voxel-select
 *   [options]="options"
 *   [label]="'simples'"
 *   [id]="'select1'"
 *   (optionChange)="onOption($event)"></voxel-select>
 *
 * @example desabilitado
 * <voxel-select
 *   [options]="options"
 *   [label]="'simples'"
 *   [id]="'select1'"
 *   [disabled]="true"
 *   (optionChange)="onOption($event)"></voxel-select>
 *
 * @example obrigatÃ³rio
 * <voxel-select
 *   [options]="options"
 *   [label]="'simples'"
 *   [id]="'select1'"
 *   [required]="true"
 *   (optionChange)="onOption($event)"></voxel-select>
 */
@Component({
  selector: 'voxel-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class VoxelSelectComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-select';

  /**
   * @internal
   * Elemento de input
   */
  @ViewChild('inputSelect') inputSelect: ElementRef;

  /**
   * ID do elemento input
   */
  @Input()
  id: string;

  /**
   * Placeholder
   */
  @Input()
  placeholder = 'placeholder';

  /**
   *  Label do elemento
   */
  @Input()
  label: string;

  /**
   * Lista de items (option) da lista
   */
  @Input()
  options: IOption[];

  /**
   * Informa obrigatoriedade do campo
   */
  @Input()
  required = false;

  /**
   *  Desabilitar interaÃ§Ã£o com o componente
   */
  @Input()
  disabled = false;

  /**
   * Flag para inserir texto de campo opcional apÃ³s o label
   */
  @Input()
  optional = false;

  /**
   * Flag deixar componente somente leitura ou nÃ£o
   */
  @Input()
  readonly = false;

  /**
   * Flag para inserir texto de campo opcional apÃ³s o label
   */
  @Input()
  autocomplete = false;

  /**
   * OpÃ§Ã£o selecionada
   */
  @Input()
  get option(): IOption | undefined {
    return this.selectedValue;
  }
  set option(option: IOption | undefined) {
    this.selectedValue = option;
    this.optionChange.emit(this.selectedValue);
  }

  /**
   * Evento disparado quando um item Ã© selecionado
   */
  @Output()
  optionChange = new EventEmitter<IOption>();

  /**
   * @internal
   */
  selectedValue: IOption | undefined;

  /**
   * @internal
   */
  value = '';

  /**
   * @internal
   */
  isInFocus = false;

  /**
   * @internal
   */
  optionId = this.id;

  /**
   * @internal
   */
  activeDescendant = '';

  /**
   * @internal
   */
  indexActiveDescendant: number | undefined = 0;

  /**
   * @internal
   */
  isError = false;

  /**
   * @internal
   */
  instanceId = this.id + '-options';

  /**
   * @internal
   */
  optionList: IOption[];

  /**
   * @internal
   */
  query = '';

  /**
   * @internal
   */
  segment: Observable<SegmentTypes> = this.segmentService.segment;

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
    private segmentService: VoxelSegmentService,
  ) { }

  ngOnChanges() {
    this.validateParam.validatePropertyOnObject(
      this,
      VoxelSelectComponent.selector,
      ['id'],
    );

    if (this.optional && !this.required && this.label) {
      this.label += ' (opcional)';
    }

    if (!this.required) {
      this.options.splice(0, 0, { label: 'selecione', value: '', selected: false } as IOption);
    }
    this.instanceId = this.id + '-options';

    this.optionList = Array.from(this.options);
    const option = this.options.find(opt => opt.selected === true);
    if (!option) {
      return;
    }
    this.value = option.label;
    this.option = option;
    this.setActiveDescendantFocus(this.options.indexOf(option));

  }

  /**
   * @internal
   */
  onFocus(isExternalFocus = false) {
    this.indexActiveDescendant = undefined;
    this.activeDescendant = '';
    this.isError = false;
    if (!this.disabled && !this.readonly) {
      this.isInFocus = true;
    }

    if (isExternalFocus) {
      this.inputSelect.nativeElement.focus();
    }
  }

  /**
   * @internal
   */
  onInputBlur() {
    this.validateRequired();
    this.isInFocus = false;
    this.setActiveDescendantFocus(this.selectedValue
      ? this.options.indexOf(this.selectedValue)
      : 0,
    );
  }

  private validateRequired() {
    this.isError = this.required && !this.selectedValue;
  }

  /**
   * @internal
   */
  updateValue(option: IOption) {
    this.cleanSearch();
    this.updateSelectOption(option);
    this.isInFocus = false;
    this.isError = false;
  }

  private updateSelectOption(option: IOption) {
    if (option.value === '') {
      this.value = '';
      this.setActiveDescendantFocus(0);
      return;
    }

    this.option = option;
    this.value = option.label;
    option.selected = true;
    this.setActiveDescendantFocus(this.optionList.indexOf(option));
  }

  private setActiveDescendantFocus(index: number) {
    this.indexActiveDescendant = index;
    this.activeDescendant = `${this.instanceId}-${index}`;
  }
  /**
   * @internal
   */
  onEnterKeyPress() {
    this.isInFocus = !this.isInFocus;
    // tslint:disable-next-line:early-exit
    if (!this.isInFocus) {
      this.options.forEach(option => option.selected = false);
      // // tslint:disable-next-line:no-non-null-assertion
      // this.updateSelectOption(this.options[this.indexActiveDescendant!]);
      // tslint:disable-next-line:no-non-null-assertion
      this.updateValue(this.options[this.indexActiveDescendant!]);
      this.inputSelect.nativeElement.focus();
    }
  }

  /**
   * @internal
   */
  onArrowDownKeyPress($event: Event) {
    $event.preventDefault();
    if (!this.isInFocus) { return; }
    if (this.indexActiveDescendant === undefined) {
      this.setActiveDescendantFocus(0);
    } else if (this.indexActiveDescendant + 1 < this.options.length) {
      this.setActiveDescendantFocus(this.indexActiveDescendant + 1);
    }
  }

  /**
   * @internal
   */
  onArrowUpKeyPress($event: Event) {
    $event.preventDefault();
    if (!this.isInFocus) { return; }
    // tslint:disable-next-line:no-non-null-assertion
    if (this.indexActiveDescendant! - 1 < 0) {
      return;
    }
    // tslint:disable-next-line:no-non-null-assertion
    this.setActiveDescendantFocus(this.indexActiveDescendant! - 1);
  }

  /**
   * @internal
   */
  onSpaceKeyPress($event: Event) {
    if (this.autocomplete) {
      return;
    }

    $event.preventDefault();
    this.isInFocus = true;
  }

  /**
   * @internal
   */
  onEscapeKeyPress() {
    this.isInFocus = false;
  }

  /**
   * @internal
   */
  onValueChange(value: string) {
    if (this.selectedValue) {
      this.options.forEach(option => option.selected = false);
      this.selectedValue = undefined;
    }
    this.isInFocus = true;
    this.query = value;
    const pattern = value
    .replace(/\s{1,}/g, ' ')
    .trim();

    const queryPattern = new RegExp(pattern, 'i');

    this.options = this.optionList.filter(option => option.label.match(queryPattern));
    if (this.options.length > 0) {
      this.setActiveDescendantFocus(0);
    }
  }

  /**
   * @internal
   */
  cleanSearch() {
    this.query = '';
    if (this.options.length > 0) {
      this.options.forEach(option => option.selected = false);
      this.setActiveDescendantFocus(0);
    }
    this.options = Array.from(this.optionList);
    this.value = '';
  }
}
