import { Component, Input, OnChanges } from '@angular/core';

import { VoxelValidateParameterService } from './../../services/validate-parameter/validate-parameter.service';

/**
 * Interface de tamanho do componente loading circular
 */
export type ILoadingCircleSize = 's' | 'm' | 'l';

const RadioSizes = Object.freeze({
  s: 10,
  m: 15,
  l: 18,
});

/**
 * O loading circular indica ao usuÃ¡rio o status de um processo que estÃ¡ sendo realizado,
 * exclusivo para o carregamento interno de uma sessÃ£o especÃ­fica dentro da pÃ¡gina.
 *
 * Componente loading-circle
 *
 * <example-url>../../demo/index.html#/portfolio/loadingcircle?componentOnly=true</example-url>
 *
 * @example Normal (default medium size="m")
 * <voxel-loading-circle
 *   [show]="true"
 *   loadingMessage="Carregando o serviÃ§o da lista de usuÃ¡rio"
 *   loadedMessage="Carregado com sucesso">
 * </voxel-loading-circle>
 *
 * @example Tamanho (small)
 * <voxel-loading-circle
 *   [show]="true"
 *   loadingMessage="Carregando o serviÃ§o da lista de usuÃ¡rio"
 *   loadedMessage="Carregado com sucesso"
 *   size="s">
 * </voxel-loading-circle>
 *
 * @example Tamanho (large)
 * <voxel-loading-circle
 *   [show]="true"
 *   loadingMessage="Carregando o serviÃ§o da lista de usuÃ¡rio"
 *   loadedMessage="Carregado com sucesso"
 *   size="l">
 * </voxel-loading-circle>
 *
 * @example Estilo dark
 * <voxel-loading-circle
 *   [show]="true"
 *   loadingMessage="Carregando o serviÃ§o da lista de usuÃ¡rio"
 *   loadedMessage="Carregado com sucesso"
 *   dark="true">
 * </voxel-loading-circle>
 *
 */

@Component({
  selector: 'voxel-loading-circle',
  templateUrl: './loading-circle.component.html',
  styleUrls: [ './loading-circle.component.scss'],
})
export class VoxelLoadingCircleComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-loading-circle';

  /**
   * Controla a exibiÃ§Ã£o do loading
   */
  @Input()
  show = false;

  /**
   * @internal
   * Gerencia o status de carregamento ou conclusÃ£o
   */
  isLoading = false;

  /**
   * DefiniÃ§Ã£o do tamanho do loading circular
   */
  @Input()
  size: ILoadingCircleSize = 'm';

  /**
   * @internal
   * Define o radius do svg
   */
  radius: number;

  /**
   * Alterna a cor do loading para contrastar em fundos claros (false) ou escuros (true)
   */
  @Input()
  dark = false;

  /**
   * Mensagem para acessibilidade lido no inicio do carregamento
   */
  @Input()
  loadingMessage: string;

  /**
   * Mensagem para acessibilidade lido na conclusÃ£o do carregamento
   */
  @Input()
  loadedMessage: string;

  constructor(
    private validateParam: VoxelValidateParameterService,
  ) { }

  ngOnChanges() {
    this.validateRequiredParams();
    this.radius = RadioSizes[this.size];
    this.setLoadingWithAccessibility();
  }

  private setLoadingWithAccessibility() {
    setTimeout(() => this.isLoading = this.show);
  }

  /**
   * @internal
   */
  validateRequiredParams() {
    this.validateParam.validatePropertyOnObject(
      this,
      VoxelLoadingCircleComponent.selector,
      ['loadingMessage', 'loadedMessage'],
    );
  }

}
