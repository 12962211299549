import { NgModule } from '@angular/core';

import { VoxelValidateParameterModule } from './../../services/validate-parameter/validate-parameter.module';
import { VoxelCardComponent } from './card.component';

export * from './card.component';

@NgModule({
  imports: [
    VoxelValidateParameterModule,
  ],
  providers: [
  ],
  declarations: [VoxelCardComponent],
  exports: [VoxelCardComponent],
})
export class VoxelCardModule { }
