import { Directive, HostListener, Input } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { VoxelStepperComponent } from './stepper.component';

/**
 * Diretiva para navegar no Stepper usando botÃµes
 *
 * <example-url>../../demo/#/portfolio/stepper?componentOnly=true</example-url>
 *
 * @example
 * <voxel-stepper>
 *   <voxel-step label="Lorem">
 *     <!-- conteudo do step aqui -->
 *     <voxel-button voxelStepperNavigation="prev" secondary="true">voltar</voxel-button>
 *     <voxel-button voxelStepperNavigation="next">continuar</voxel-button>
 *   </voxel-step>
 * </voxel-stepper>
 *
 */
@Directive({
  selector: '[voxelStepperNavigation]',
})
export class VoxelStepperNavigationDirective {
  constructor(
    private stepper: VoxelStepperComponent,
  ) {}

  @Input('voxelStepperNavigation')
  direction: 'prev' | 'next';

  // tslint:disable-next-line:no-input-rename
  @Input('voxelStepperNavigationAsync')
  resolve: Observable<any> | Promise<any>;

  /**
   * @internal
   */
  @HostListener('mouseup')
  onMouseUp() {
    this.direction === 'prev'
      ? this.stepper.prevStep()
      : this.stepper.nextStep(this.resolve);
  }
}
