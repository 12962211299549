import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { VoxelSegmentModule } from './../../services/segment/segment.module';
import { VoxelValidateParameterModule } from './../../services/validate-parameter/validate-parameter.module';
import { VoxelOptionsModule } from './../options/options.module';
import { VoxelSelectComponent } from './select.component';
import { VoxelIconModule } from '../icon/icon.module';

export * from './select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    VoxelOptionsModule,
    VoxelValidateParameterModule,
    VoxelSegmentModule,
    VoxelIconModule,
  ],
  declarations: [VoxelSelectComponent],
  exports: [VoxelSelectComponent],
})
export class VoxelSelectModule { }
