import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { VoxelValidateParameterService } from '../../services/validate-parameter/validate-parameter.service';
import { SegmentTypes, VoxelSegmentService } from './../../services/segment/segment.module';

/**
 * Dados dos eventos do textarea
 */
export interface ITextareaEvent {
  /**
   *  Evento nativo do textarea
   */
  $event: Event;
  /**
   *  IdentificaÃ§Ã£o Ãºnica do textarea
   */
  id: string;
  /**
   *  Label usado no textarea
   */
  label: string;
  /**
   *  Valor limpo do textarea
   */
  maxlength: number;
  /**
   *  Valor do textarea no momento do evento
   */
  value: string;
  /**
   *  VerificaÃ§Ã£o se o textarea Ã© obrigatÃ³rio
   */
  required: boolean;
  /**
   *  VerificaÃ§Ã£o se o textarea Ã© estÃ¡ vÃ¡lido
   */
  valid: boolean;
}

/**
 * O textarea Ã© usado para que o usuÃ¡rio possa inserir ou visualizar dados,
 * geralmente um conteÃºdo textual extenso que necessite diversas linhas para transmitir uma mensagem.
 * Pode apresentar um contador de caracteres para auxiliar o usuÃ¡rio a nÃ£o ultrapassar o tamanho
 * mÃ¡ximo de conteÃºdo permitido no campo.
 *
 * <example-url>../../demo/index.html#/portfolio/textarea?componentOnly=true</example-url>
 *
 * @example autofocus
 * <voxel-textarea
 *   [id]="'input-value-text0'"
 *   [autofocus]="true"
 *   [label]="'textarea simples'"></voxel-textarea>
 *
 * @example simples
 * <voxel-textarea
 *   [id]="'input-value-text1'"
 *   [label]="'textarea simples'"></voxel-textarea>
 *
 * @example placeholder
 * <voxel-textarea
 *   [id]="'input-value-text2'"
 *   [placeholder]="'placeholder'"
 *   [label]="'textarea com placeholder'"></voxel-textarea>
 *
 * @example desabilitado
 * <voxel-textarea
 *   [id]="'input-value-text3'"
 *   [placeholder]="'placeholder'"
 *   disabled="true"
 *   [label]="'textarea desabilitado'"></voxel-textarea>
 *
 * @example obrigatÃ³rio
 * <voxel-textarea
 *   [id]="'input-value-text4'"
 *   [placeholder]="'placeholder'"
 *   [maxlength]="700"
 *   required="true"
 *   [label]="'textarea required'"></voxel-textarea>
 *
 * @example somente leitura
 * <voxel-textarea
 *   [id]="'input-value-text5'"
 *   [placeholder]="'placeholder'"
 *   [maxlength]="700"
 *   [readonly]="true"
 *   [value]="'Lorem Ipsum is simply dummy text of the printing and typesetting industry.
 *     Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,
 *     when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 *     It has survived not only five centuries, but also the leap into electronic typesetting,
 *     remaining essentially unchanged.'"
 *   [label]="'textarea somente leitura'"></voxel-textarea>
 *
 * @example opcional
 * <voxel-textarea
 *   [id]="'input-value-text6'"
 *   [placeholder]="'placeholder'"
 *   [maxlength]="700"
 *   [optional]="true"
 *   [label]="'textarea simples'"></voxel-textarea>
 *
 * @example sem label
 * <voxel-textarea
 *   [id]="'input-value-text7'"
 *   [a11yLabel]="'label, textarea sem label'"></voxel-textarea>
 */

@Component({
  selector: 'voxel-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class VoxelTextareaComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-textarea';

  /**
   *  Label do elemento
   */
  @Input()
  label = '';

  /**
   * ID do elemento textarea
   */
  @Input()
  id: string;

  /**
   * Placeholder
   */
  @Input()
  placeholder?: string;

  /**
   *  Desabilitar interaÃ§Ã£o com o componente
   */
  @Input()
  disabled?: boolean;

  /**
   * Informa obrigatoriedade do campo
   */
  @Input()
  required = false;

  /**
   * Mensagem de erro que deverÃ¡ ser mostrada ao usuÃ¡rio.
   * Deve ser utilizado com o campo required="true", serÃ¡ verificado se o campo esta em branco ou undefined,
   * para exibir a mensagem de erro
   */
  @Input()
  errorMessage = 'O preenchimento Ã© obrigatÃ³rio';

  /**
   * Valor para maxlength do textarea
   */
  @Input()
  maxlength = 400;

  /**
   * Mensagem para outros tratamentos de erros, para tratamento de outros tipos de validaÃ§Ã£o
   */
  @Input()
  customErrorMessage = '';

  /**
   * Tratar a acessibilidade
   */
  @Input()
  a11yLabel = '';

  /**
   * Valor do campo
   */
  @Input()
  value = '';

  /**
   * Flag para exibir textarea como somente leitura
   */
  @Input()
  readonly?: boolean;

  /**
   * Iniciar textarea com foco
   */
  @Input()
  autofocus?: boolean;

  /**
   * Flag para inserir texto de campo opcional apÃ³s o label
   */
  @Input()
  optional = false;

  /**
   * Evento disparado quando o textarea perder o foco.
   */
  @Output()
  textareaBlur = new EventEmitter<ITextareaEvent>();

  /**
   * Evento disparado quando o textarea obter o foco.
   */
  @Output()
  textareaFocus = new EventEmitter<ITextareaEvent>();

  /**
   * Evento disparado quando o textarea obter o foco.
   */
  @Output()
  textareaChange = new EventEmitter<ITextareaEvent>();

  /**
   * Flag de indicaÃ§Ã£o se textarea estÃ¡ com foco
   * @internal
   */
  isInFocus = false;

  /**
   * Flag de indicaÃ§Ã£o se textarea estÃ¡ com erro
   * @internal
   */
  isError = false;

  /**
   * Flag de indicaÃ§Ã£o se textarea estÃ¡ vÃ¡lido
   * @internal
   */
  isValid = true;

  /**
   * @internal
   */
  errorMessageCurrent = '';

  /**
   * @internal
   */
  a11yId = '-a11y';

  /**
   * @internal
   */
  textareaValue = '';

  /**
   * @internal
   */
  containerClasses = this.getContainerCssClasses();

  /**
   * @internal
   */
  labelClasses = this.getLabelCssClasses();

  /**
   * @internal
   */
  segment: Observable<SegmentTypes> = this.segmentService.segment;

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
    private segmentService: VoxelSegmentService,
  ) { }

  /**
   * @internal
   */
  ngOnChanges(changes: SimpleChanges) {
    this.checkParameters();
    this.checkRequiredValueError(this.value);
    this.checkValueCustomError(this.value);

    if (this.optional && !this.required) {
      this.label = this.label.concat(' (opcional)');
    }

    if (changes.customErrorMessage !== undefined && changes.customErrorMessage.currentValue !== undefined) {
      this.changeErrorMessage(changes.customErrorMessage.currentValue);
    }

    this.containerClasses = this.getContainerCssClasses();
    this.labelClasses = this.getLabelCssClasses();
  }

  /**
   * @internal
   */
  getContainerCssClasses() {
    return {
      'textarea-container--error': this.isError === true
        && this.errorMessageCurrent !== undefined
        && this.errorMessageCurrent !== '',
      'textarea-container-read-only': this.readonly,
    };
  }

  /**
   * @internal
   */
  getLabelCssClasses() {
    return {
      'textarea-label__error': this.isError === true && this.errorMessageCurrent !== undefined && this.errorMessageCurrent !== '',
      'label__focus': this.isInFocus === true
        && (this.errorMessageCurrent !== undefined || this.errorMessageCurrent !== '')
        && !this.readonly,
      'textarea-disabled': this.disabled,
    };
  }

  /**
   * @internal
   */
  checkParameters() {
    const { selector } = VoxelTextareaComponent;
    this.validateParam.validateEmptyString(this.id, selector, 'id');
    this.validateParam.validateTypes(this.errorMessage, selector, 'errorMessage', ['string']);
  }

  /**
   * @internal
   */
  checkRequiredValueError(value: any) {
    if (this.verifyValueEmptyOrUndefined(value) && this.required) {
      this.isValid = false;
      this.changeErrorMessage(this.errorMessage);
      return;
    }
    this.isValid = true;
  }

  /**
   * @internal
   */
  checkValueCustomError(value: any) {
    if (!this.verifyValueEmptyOrUndefined(value) && !this.verifyValueEmptyOrUndefined(this.customErrorMessage)) {
      this.isValid = false;
      this.changeErrorMessage(this.customErrorMessage);
      return;
    }
    this.isValid = true;
  }

  /**
   * @internal
   */
  clearErrorMessages() {
    if (this.isValid && (this.customErrorMessage === undefined || this.customErrorMessage === '')) {
      this.changeErrorMessage('');
    }
  }

  /**
   * @internal
   */
  validateRequired($event: Event) {
    const target = $event.target as HTMLTextAreaElement;
    this.checkRequiredValueError(target.value);
    this.clearErrorMessages();

    if (this.isValid) {
      this.textareaChange.emit(this.createEmit($event));
    }
  }

  /**
   * @internal
   */
  onTextareaFocus($event: Event) {
    this.isInFocus = true;
    this.isError = true;

    this.textareaFocus.emit(this.createEmit($event));
  }

  /**
   * @internal
   */
  onTextareaBlur($event: Event) {
    this.isInFocus = false;

    this.checkRequiredValueError(this.value);
    this.checkValueCustomError(this.value);
    this.containerClasses = this.getContainerCssClasses();
    this.labelClasses = this.getLabelCssClasses();
    this.textareaBlur.emit(this.createEmit($event));
  }

  /**
   * @internal
   */
  changeErrorMessage(message = '') {
    this.isError = message !== undefined && message !== '';
    this.errorMessageCurrent = message;
  }

  /**
   * @internal
   */
  verifyValueEmptyOrUndefined(value: string): boolean {
    return value === undefined || value === '';
  }

  /**
   * @internal
   */
  setMaxLength(value: string) {
    this.textareaValue = value;
  }

  /**
   * @internal
   */
  createEmit($event: Event): ITextareaEvent {
    return {
      $event,
      id: this.id,
      label: this.label,
      maxlength: this.maxlength,
      value: this.value,
      required: this.required,
      valid: this.isValid,
    };
  }

  /**
   * @internal
   */
  getTextareaInformation() {
    const label = this.label || '';
    const maxLengthDescription = this.maxlength ? `mÃ¡ximo ${this.maxlength} ${this.maxlength <= 1 ? 'caracter' : 'caracteres'}` : '';
    return `${label} ${this.a11yLabel}, ${maxLengthDescription}`;
  }
}
