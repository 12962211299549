import { Component, ContentChildren, HostBinding, Input, OnChanges, QueryList } from '@angular/core';

import { VoxelButtonComponent } from '../../button/button.component';
import { VoxelValidateParameterService } from './../../../services/validate-parameter/validate-parameter.service';

/**
 * O Step define o conteudo de um passo junto ao componente Stepper.
 *
 * <example-url>../../demo/index.html#/portfolio/stepper?componentOnly=true</example-url>
 *
 * @example Next
 * <voxel-button voxelStepperNavigation="next">continuar</voxel-button>
 *
 * @example Prev
 * <voxel-button voxelStepperNavigation="prev" secondary="true">voltar</voxel-button>
 *
 * @example Next Async
 * <voxel-button voxelStepperNavigation="next" [voxelStepperNavigationAsync]="somethingAsync()">continuar</voxel-button>
 *
 */
@Component({
  selector: 'voxel-step',
  templateUrl: './step.component.html',
  styleUrls: ['../stepper.component.scss'],
})
export class VoxelStepComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-radio-button';

  /**
   * Texto a ser exibido na aba/passo.
   */
  @Input() label: string;

  /**
   * @internal
   */
  @ContentChildren(VoxelButtonComponent) actionButtons: QueryList<VoxelButtonComponent>;

  /**
   * @internal
   */
  @HostBinding('class.current') currentStep: boolean;

  /**
   * @internal
   */
  @HostBinding('class.tabpanel') tabPanelClass = true;

  /**
   * @internal
   */
  @HostBinding('attr.aria-labelledby') labelledBy = '';

  /**
   * @internal
   */
  @HostBinding('attr.role') role = 'tabpanel';

  /**
   * @internal
   */
  @HostBinding('attr.id') id = '';

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
  ) { }

  /**
   * @internal
   */
  ngOnChanges() {
    this.validateParam.validatePropertyOnObject(
      this,
      VoxelStepComponent.selector,
      ['label'],
    );
  }

  /**
   * Exibe o conteudo do step
   */
  show() {
    this.currentStep = true;
  }

  /**
   * Oculta o conteudo do step
   */
  hide() {
    this.currentStep = false;
  }
}
