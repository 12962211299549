import {
  Directive,
  Input,
  OnInit,
  ViewContainerRef,
  ComponentFactoryResolver,
  ElementRef,
} from '@angular/core';

import {
  VoxelTooltipComponent,
  ITooltipType,
  ITooltipPosition,
  ITooltipAlignArrow,
} from './tooltip.component';

interface IIndexString {
  [index: string]: string;
}

/**
 * Diretiva Tooltip
 *
 * Diretivas tooltips sÃ£o usadas para criar uma tooltip em elementos, adicionando assim uma informaÃ§Ã£o adicional.
 * SÃ£o visualizados ao se focar ou passar o mouse sobre um elemento que possua uma tooltip.
 *
 * <example-url>../../demo/#/portfolio/tooltip?componentOnly=true</example-url>
 *
 * @example Dica
 * <span
 *   voxelTooltip="Ajuda">?
 * </span>
 *
 * @example InformaÃ§Ã£o adicional
 * <span
 *   voxelTooltip="Leia mais"
 *   voxelTooltipType="info">...
 * </span>
 *
 * @example Aviso
 * <span
 *   voxelTooltip="Cuidado"
 *   voxelTooltipType="warning">!
 * </span>
 *
 * @example Erro
 * <span
 *   voxelTooltip="Ops"
 *   voxelTooltipType="error">x
 * </span>
 *
 */
@Directive({
  selector: '[voxelClickTooltip], [voxelTooltip]',
})
export class VoxelTooltipDirective implements OnInit {
  /**
   * @internal
   */
  text = '';

  /**
   * @internal
   */
  description = '';

  /**
   * Define a posiÃ§Ã£o em relaÃ§Ã£o ao elemento, que a tooltip serÃ¡ exibida (top, bottom, left, right)
   */
  @Input()
  voxelTooltipPosition: ITooltipPosition = 'top';

  /**
   * Define o alinhamento da seta da tooltip.
   */
  @Input()
  voxelTooltipAlignArrow: ITooltipAlignArrow = 'middle';

  /**
   * Define o tipo da tooltip.
   */
  @Input()
  voxelTooltipType: ITooltipType = 'tip';

  /**
   * @internal
   */
  private types: IIndexString = {
    tip: 'dica',
    info: 'informaÃ§Ã£o adicional',
    warning: 'aviso',
    error: 'erro',
  };

  /**
   * @internal
   */
  constructor(
    private container: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private elRef: ElementRef,
  ) {
    const element = this.elRef.nativeElement;
    this.text = element.getAttribute('voxelTooltip')
      || element.getAttribute('voxelClickTooltip');

    this.description = [
      element.getAttribute('label'),
      element.getAttribute('a11yLabel'),
    ]
      .filter(string => string)
      .join(', ');
  }

  /**
   * @internal
   */
  ngOnInit() {
    // create a tooltip component
    this.container.clear();
    const factory = this.resolver.resolveComponentFactory(VoxelTooltipComponent);
    const tooltip = this.container.createComponent(factory);
    const element = this.elRef.nativeElement;

    // set tooltip props
    tooltip.instance.text = this.text;
    tooltip.instance.position = this.voxelTooltipPosition;
    tooltip.instance.alignArrow = this.voxelTooltipAlignArrow;
    tooltip.instance.type = this.voxelTooltipType;
    tooltip.location.nativeElement.setAttribute('position', this.voxelTooltipPosition);
    tooltip.location.nativeElement.setAttribute('type', this.voxelTooltipType);
    tooltip.location.nativeElement.setAttribute('align-arrow', this.voxelTooltipAlignArrow);

    // set element as position relative to tooltip not flow
    const styles = getComputedStyle(element);
    if (styles.position === 'static' || !styles.position) {
      element.style.position = 'relative';
    }

    // set accessibility
    // element.setAttribute('role', 'tooltip');
    element.setAttribute('aria-label', this.description || this.elRef.nativeElement.textContent);
    const id = Math.random().toString(36).substr(2, 9);
    tooltip.location.nativeElement.id = `tooltip-${id}`;
    element.setAttribute('aria-describedby', `tooltip-${id}`);
    tooltip.location.nativeElement.setAttribute('aria-label', `${this.types[this.voxelTooltipType]}, ${this.text}`);

    // read tooltip on enter
    // element.addEventListener('keypress', (event: any) => {
    //   if (event.key !== 'Enter') {
    //     return false;
    //   }

    //   console.log('enter');

    //   tooltip.location.nativeElement.style.display = 'inline-block';
    //   tooltip.location.nativeElement.style.opacity = 1;

    //   tooltip.location.nativeElement.setAttribute('tabindex', '0');
    //   tooltip.location.nativeElement.focus();
    // });

    if (element.hasAttribute('voxelClickTooltip')) {
      element.addEventListener('click', () => {
        element.classList.toggle('tooltip--visible');
      });

      document.addEventListener('click', (event: any) => {
        if (!event.target.matches('[voxelClickTooltip]')) {
          element.classList.remove('tooltip--visible');
        }
      });
    }

    // put tooltip component inside element
    element.appendChild(tooltip.location.nativeElement);
  }
}
