import { Component, Input, OnChanges } from '@angular/core';

import { ValidateParameterNotifierService } from '../../services/validate-parameter/validate-parameter-notifier.service';

/**
 * Componente de Badge
 *
 * Usado para informar o usuÃ¡rio sobre uma quantidade de de coisas remanescentes.
 *
 * <example-url>../../demo/index.html#/portfolio/badge?componentOnly=true</example-url>
 *
 *
 * @example Badge com 10 itens
 * <voxel-badge value="10"></voxel-badge>
 *
 *
 * @example Badge com valor acima do mÃ¡ximo permitido (99)
 * <voxel-badge value="100"></voxel-badge>
 *
 *
 * @example Badge com mÃ¡ximo customizado
 * <voxel-badge value="100" max="250"></voxel-badge>
 *
 */
@Component({
  selector: 'voxel-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class VoxelBadgeComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-badge';

  /**
   * Valor a ser exibido
   */
  @Input()
  value = 0;

  /**
   * Valor mÃ¡ximo a ser exibido
   */
  @Input()
  max = 99;

  /**
   * @internal
   */
  outOfLimit = false;

  /**
   * @internal
   */
  disabled = true;

  constructor(
    private validationNotifier: ValidateParameterNotifierService,
  ) {}

  /**
   * @internal
   */
  ngOnChanges() {
    this.outOfLimit = this.value > this.max;
    this.disabled = this.value <= 0;

    if (this.value < 0) {
      this.value = 0;
      this.validationNotifier.notify('property value must be a positive number');
    }
  }
}
