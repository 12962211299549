import { NgModule } from '@angular/core';

import { VoxelValidateParameterModule } from '../../services/validate-parameter/validate-parameter.module';
import { VoxelTooltipComponent } from './tooltip.component';
import { VoxelTooltipDirective } from './tooltip.directive';

export * from './tooltip.component';
export * from './tooltip.directive';

@NgModule({
  imports: [
    VoxelValidateParameterModule,
  ],
  declarations: [
    VoxelTooltipComponent,
    VoxelTooltipDirective,
  ],
  exports: [
    VoxelTooltipComponent,
    VoxelTooltipDirective,
  ],
  entryComponents: [
    VoxelTooltipComponent,
  ],
})
export class VoxelTooltipModule { }
