import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VoxelIconComponent } from './icon.component';
import { VoxelValidateParameterModule } from '../../services/validate-parameter/validate-parameter.module';
import { VoxelValidateContentDirectiveModule } from '../../directives/validate-content/validate-content.module';

export * from './icon.component';

@NgModule({
  imports: [
    CommonModule,
    VoxelValidateParameterModule,
    VoxelValidateContentDirectiveModule,
  ],
  declarations: [VoxelIconComponent],
  exports: [VoxelIconComponent],
})
export class VoxelIconModule { }
