import { Component, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { SegmentTypes, VoxelSegmentService } from './../../services/segment/segment.module';
import { VoxelValidateParameterService } from './../../services/validate-parameter/validate-parameter.service';

/**
 * Executa uma aÃ§Ã£o especÃ­fica que deve ser claramente expressa por sua literal.
 * Aqui trataremos dos botÃµes primÃ¡rios e secundÃ¡rios
 * (os botÃµes de navegaÃ§Ã£o e de aÃ§Ã£o sÃ£o tratados no item "Barra de navegaÃ§Ã£o" e os de "Ir para").
 *
 * <example-url>../../demo/index.html#/portfolio/bullets?componentOnly=true</example-url>
 *
 * @example lista de itens
 * itemsList = ['item 1', 'item 2', 'item 3'];
 *
 * @example bullets
 * <voxel-bullets [items]="itemsList">
 * </voxel-bullets>
 */
@Component({
  selector: 'voxel-bullets',
  templateUrl: './bullets.component.html',
  styleUrls: ['./bullets.component.scss'],
})
export class VoxelBulletsComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-bullets';

  /**
   * Lista de itens da lista
   */
  @Input()
  items: string[] = [];

  /**
   * @internal
   */
  segment: Observable<SegmentTypes> = this.segmentService.segment;

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
    private segmentService: VoxelSegmentService,
  ) { }

  /**
   * @internal
   */
  ngOnChanges() {
    this.validateParam.validatePropertyOnObject(
      this,
      VoxelBulletsComponent.selector,
      ['items'],
    );
  }
}
