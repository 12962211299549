import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

export interface IDateEvent {
  event: Event;
  value: string;
  clearMask: string;
}

/**
 * Diretiva para utilizaÃ§Ã£o de datas em inputs
 *
 * @example <input voxelDate (dateValue)="emitDate($event)">
 */
@Directive({
  selector: '[voxelDate]',
})
export class VoxelDateDirective {

  /**
   * Tipo de mÃ¡scara a ser usado
   */
  @Input()
  maskType = '';

  @Output()
  dateValue = new EventEmitter<IDateEvent>();

  private maxLengthDateMask = 8;

  private maskTypeDate = 'date';

  @HostListener('input', ['$event'])
  onKeyUp($event: Event) {
    if (this.dontApplyMask()) {
      return;
    }

    this.validate($event);
  }

  @HostListener('blur', ['$event'])
  onBlur($event: Event) {
    if (this.dontApplyMask()) {
      return;
    }

    this.validate($event);
  }

  /**
   * @internal
   */
  dontApplyMask() {
    return this.maskType !== this.maskTypeDate;
  }

  /**
   * @internal
   */
  hasNotValidLength(value: string) {
    return value.length > this.maxLengthDateMask;
  }

  /**
   * @internal
   */
  validate($event: Event) {
    const inputHtmlElement = $event.target as HTMLInputElement;
    let value = inputHtmlElement.value.replace(/\D/g, '');

    if (this.hasNotValidLength(value)) {
      value = this.validateMaxLength(value);
    }

    if (value.length <= this.maxLengthDateMask) {
      value = this.applyDateMask(value);
    }

    this.updateValues($event, value);
  }

  /**
   * @internal
   */
  validateMaxLength(value: string) {
    return value.substring(0, this.maxLengthDateMask);
  }

  /**
   * @internal
   */
  applyDateMask(value: string) {
    return value
      .replace(/^(\d{2})(\d)/, '$1/$2')
      .replace(/^(\d{2})\/(\d{2})(\d)/, '$1/$2/$3');
  }

  /**
   * @internal
   */
  updateValues($event: Event, value: string) {
    const inputHtmlElement = $event.target as HTMLInputElement;
    inputHtmlElement.value = value;
    this.dateValue.emit({
      event: $event,
      value,
      clearMask: value.replace(/\D/g, ''),
    });
  }
}
