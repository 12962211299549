import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SegmentTypes, SegmentTypeDefault } from './segment.interface';

@Injectable({
  providedIn: 'root',
})
export class VoxelSegmentService {
  private _segment = new BehaviorSubject<SegmentTypes>(this.defaultSegment);

  /**
   * Observable de Segmento
   */
  segment = this._segment.asObservable();

  constructor(
    @Inject(SegmentTypeDefault) private defaultSegment: SegmentTypes,
  ) { }

  /**
   * Envia novo valor de Segmento para os subscribers
   *
   * @param value Novo valor de Segmento
   * @returns void
   */
  changeSegment(value: SegmentTypes) {
    this._segment.next(value);
  }
}
