import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { VoxelAutoHeightDirectiveModule } from './../../directives/auto-height/auto-height.module';
import { VoxelValidateParameterModule } from './../../services/validate-parameter/validate-parameter.module';
import { VoxelSegmentModule } from './../../services/segment/segment.module';
import { VoxelTextareaComponent } from './textarea.component';

export * from './textarea.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    VoxelAutoHeightDirectiveModule,
    VoxelValidateParameterModule,
    VoxelSegmentModule,
  ],
  providers: [
  ],
  declarations: [VoxelTextareaComponent],
  exports: [VoxelTextareaComponent],
})
export class VoxelTextareaModule { }
