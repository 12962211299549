import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelNumberFormatterServiceModule } from './../../services/number-formatter/number-formatter.module';
import { VoxelCurrencyFormatterDirective } from './currency-formatter.directive';

@NgModule({
  imports: [
    CommonModule,
    VoxelNumberFormatterServiceModule,
  ],
  declarations: [VoxelCurrencyFormatterDirective],
  exports: [VoxelCurrencyFormatterDirective],
})
export class VoxelCurrencyFormatterDirectiveModule { }

export * from './currency-formatter.directive';
