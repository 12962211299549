import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VoxelAccordionComponent } from './accordion.component';
import { VoxelIconModule } from '../icon/icon.module';
import { VoxelValidateParameterModule } from '../../services/validate-parameter/validate-parameter.module';
import { VoxelSegmentModule } from '../../services/segment/segment.module';
import { VoxelValidateContentDirectiveModule } from '../../directives/validate-content/validate-content.module';

export * from './accordion.component';

@NgModule({
  imports: [
    CommonModule,
    VoxelValidateParameterModule,
    VoxelSegmentModule,
    VoxelIconModule,
    VoxelValidateContentDirectiveModule,
  ],
  declarations: [
    VoxelAccordionComponent,
  ],
  exports: [
    VoxelAccordionComponent,
  ],
})
export class VoxelAccordionModule { }
