import { Directive, HostListener, Input } from '@angular/core';

import { ValidateParameterNotifierService } from '../../services/validate-parameter/validate-parameter-notifier.service';

/**
 * Diretiva para receber a id de referÃªncia do prÃ³ximo elemento a ser focado
 * quando a quantidade de caracteres do input ter chagado ao mÃ¡ximo
 *
 * @example
 * <input voxelAutoTab [autoTabReferece]="'input-focusable'" maxlength="2"/>
 * <input id="input-focusable" class="input-focus"/>
 */
@Directive({
  selector: '[voxelAutoTab]',
})
export class VoxelAutoTabDirective {

  @Input()
  autoTabReferece: string;

  constructor(
    private validationNotifier: ValidateParameterNotifierService,
  ) {}

  @HostListener('input', ['$event.target'])
  onInput({ maxLength, value: { length } }: HTMLInputElement) {
    if (!(length >= maxLength) || !this.autoTabReferece) {
      return;
    }
    const autoTabElement = document.getElementById(this.autoTabReferece);
    if (!autoTabElement) {
      this.validationNotifier.notify(`The reference (${this.autoTabReferece}) to auto focus was not found.`);
    } else {
      autoTabElement.focus();
    }
  }

}
