import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { INavigationEvent } from '../navigation/navigation.component';
import { VoxelValidateParameterService } from './../../services/validate-parameter/validate-parameter.service';

export { INavigationEvent } from '../navigation/navigation.component';

/**
 * As abas sÃ£o uma maneira fÃ¡cil de organizar o conteÃºdo, agrupando informaÃ§Ãµes semelhantes na mesma pÃ¡gina.
 *
 * <example-url>../../demo/index.html#/portfolio/tabs?componentOnly=true</example-url>
 *
 *  @example Normal
 *    <voxel-tabs
 *      [tabs]="tabsnav"
 *      [id]="'tabs-nav'"
 *      [current]="currentTab"
 *      (click)="onClickTab($event)"></voxel-tabs>
 *
 */
@Component({
  selector: 'voxel-tabs',
  templateUrl: './tabs.component.html',
})
export class VoxelTabsComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-tabs';

  /**
   * Id Ãºnico do component
   */
  @Input()
  id: string;

  /**
   * Lista de Abas
   * **MÃ­nimo**: 2
   * **MÃ¡ximo**: 6
   *
   * ```js
   * // Exemplo
   * ['botÃ£o 1', 'BotÃ£o 2', 'BotÃ£o 3']
   * ```
   */
  @Input()
  tabs: string[] = [];

  /**
   * NÃºmero da aba/passo atual
   */
  @Input()
  selectedIndex = 1;

  /**
   * Evento disparado sempre que selecionar uma aba.
   *
   * **Na opÃ§Ã£o de steps esse evento nÃ£o Ã© acionado devido a regra de uso**
   */
  @Output()
  tabsChange = new EventEmitter<INavigationEvent>();

  /**
   * @internal
   */
  amount: number;

  /**
   * @internal
   */
  minLength = 2;

  /**
   * @internal
   */
  maxLength = 6;

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
  ) { }

  /**
   * @internal
   */
  ngOnChanges() {
    this.amount = this.tabs.length;

    const { selector } = VoxelTabsComponent;

    this.validateParam.validatePropertyOnObject(this, selector, ['selectedIndex', 'amount', 'tabs']);
    this.validateParam.validateFirstSmallerThanSecond(
      [this.selectedIndex, this.amount],
      selector,
      ['selectedIndex', 'amount'],
    );

    this.validateParam.validateMinLength(this.amount, this.minLength, selector, 'tabs');
    this.validateParam.validateMaxLength(this.amount, this.maxLength, selector, 'tabs');
  }

  /**
   * @internal
   */
  onClickTab($event: INavigationEvent) {
    this.tabsChange.emit($event);
  }
}
