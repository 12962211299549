import { Component } from '@angular/core';

/**
 * Componente Grid Row (parte do {@link ../additional-documentation/--guia-de-desenvolvimento.html Sistema de Grid do Voxel})
 *
 * Esse componente Ã© usado obrigatoriamente dentro de um {@link VoxelGridContainerComponent}.
 * Como o container jÃ¡ possui um padding, esse componente cria uma margem negativa para "zerar" o padding inicial dado pelo container.
 *
 * <example-url>../../demo/index.html#/portfolio/grid?componentOnly=true</example-url>
 *
 * @example
 * <voxel-container>
 *   <voxel-row>
 *     <voxel-item dc="6" tc="6" mc="12"></voxel-item>
 *   <voxel-row>
 * </voxel-container>
 */
@Component({
  selector: 'voxel-row',
  styleUrls: ['./grid.component.scss'],
  template: `
  <div class="voxel-grid__row">
    <ng-content></ng-content>
  </div>
  `,
})
export class VoxelGridRowComponent {

  /**
   * @internal
   */
  static selector = 'voxel-row';

}
