import { Component, Input, OnChanges } from '@angular/core';

import { VoxelValidateParameterService } from './../../services/validate-parameter/validate-parameter.service';

export type ILinkTargetType = '_self' | '_blank' | '_parent' | '_top';

/**
 * Componente de Link
 *
 * Links sÃ£o usados para que o usuÃ¡rio possa navegar entre pÃ¡ginas.
 * Devem se destacar em meio ao conteÃºdo para serem entendidos como algo clicÃ¡vel.
 *
 * <example-url>../../demo/index.html#/portfolio/link?componentOnly=true</example-url>
 *
 * @example Normal
 * <voxel-link
 *   href="https://www.itau.com.br/"
 *   id="link-1"
 *   content="meu link"
 *   a11yLabel="Esse Ã© um texto de descriÃ§Ã£o de teste do meu link">
 * </voxel-link>
 *
 * @example Abrindo em uma nova aba
 * <voxel-link
 *   href="https://www.itau.com.br/"
 *   target="_blank"
 *   id="link-2"
 *   content="meu link em uma nova aba"
 *   a11yLabel="Esse Ã© um texto de descriÃ§Ã£o de teste do meu link">
 * </voxel-link>
 *
 * @example Desabilitado
 * <voxel-link
 *   href="https://www.itau.com.br/"
 *   disabled="true"
 *   id="link-3"
 *   content="meu link desabilitado"
 *   a11yLabel="Esse Ã© um texto de descriÃ§Ã£o de teste do meu link desabilitado">
 * </voxel-link>
 *
 * @example Fundo Escuro
 * <voxel-link
 *   href="https://www.itau.com.br/"
 *   id="link-5"
 *   content="a:link/disabled"
 *   disabled="true"
 *   a11yLabel="descriÃ§Ã£o do link no fundo escuro"
 *   darker="true">
 * </voxel-link>
 */
@Component({
  selector: 'voxel-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class VoxelLinkComponent implements OnChanges {

  static selector = 'voxel-link';

  /**
   * Identificador Ãºnico do componente
   */
  @Input()
  id: string;

  /**
   * Url de destino do link
   */
  @Input()
  href: string;

  /**
   * Especifica o contexto em que o link serÃ¡ aberto
   */
  @Input()
  target: ILinkTargetType = '_self';

  /**
   * Texto de acessibilidade para o link
   */
  @Input()
  a11yLabel: string;

  /**
   * Texto de descriÃ§Ã£o do link
   */
  @Input()
  content: string;

  /**
   * ParÃ¢metro para exibir um link como desabilitado
   */
  @Input()
  disabled = false;

  /**
   * ParÃ¢metro para estilizaÃ§Ã£o do componente
   */
  @Input()
  darker = false;

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
  ) {}

  /**
   * @internal
   */
  ngOnChanges() {
    this.validateParam.validatePropertyOnObject(this, VoxelLinkComponent.selector, [
      'id',
      'href',
      'a11yLabel',
      'content',
    ]);
  }
}
