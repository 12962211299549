import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelValidateParameterModule } from './../../services/validate-parameter/validate-parameter.module';
import { VoxelLoadingCircleComponent } from './loading-circle.component';

export * from './loading-circle.component';

@NgModule({
  imports: [
    CommonModule,
    VoxelValidateParameterModule,
  ],
  declarations: [
    VoxelLoadingCircleComponent,
  ],
  exports: [
    VoxelLoadingCircleComponent,
  ],
})
export class VoxelLoadingCircleModule { }
