import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelCustomMaskDirective } from './custom-mask.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [VoxelCustomMaskDirective],
  exports: [VoxelCustomMaskDirective],
})
export class VoxelCustomMaskDirectiveModule { }

export * from './custom-mask.directive';
