import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { ICpfCnpjEvent } from '../../directives/cpf-cnpj/cpf-cnpj.directive';
import { ICurrencyFormaterEvent } from '../../directives/currency-formatter/currency-formatter.directive';
import { ICustomMaskEvent } from '../../directives/custom-mask/custom-mask.directive';
import { IDateEvent } from '../../directives/date/date.directive';
import { SegmentTypes, VoxelSegmentService } from '../../services/segment/segment.module';
import { VoxelCpfCnpjValidatorService } from './../../services/cpf-cnpj-validator/cpf-cnpj-validator.service';
import { VoxelCustomMaskValidatorService } from './../../services/custom-mask-validator/custom-mask-validator.service';
import { VoxelDateValidatorService } from './../../services/date-validator/date-validator.service';
import { VoxelValidateParameterService } from './../../services/validate-parameter/validate-parameter.service';

/**
 * Tipos de mÃ¡scaras aceitos pelo input
 */
export type IInputMaskType = '' | 'currency' | 'cpf' | 'cnpj' | 'cpf-cnpj' | 'date' | 'custom-mask';

/**
 * Classe de referÃªncia para Ã­cones do input
 */
export interface IIconInput {
  icon?: string;
  direction: 'left' | 'right';
  description?: string;
  isClickable: boolean;
}

/**
 * Dados dos eventos do input
 */
export interface IInputEvent {
  /**
   *  Evento nativo do input
   */
  $event: Event;
  /**
   *  IdentificaÃ§Ã£o Ãºnica do input
   */
  id: string;
  /**
   *  Label usado no input
   */
  label: string;
  /**
   *  Valor limpo do input
   */
  clearValue: string;
  /**
   *  Tipo de input
   */
  type: string;
  /**
   *  Valor do input no momento do evento
   */
  value: string | number;
  /**
   *  VerificaÃ§Ã£o se o input Ã© obrigatÃ³rio
   */
  required: boolean;
  /**
   *  VerificaÃ§Ã£o se o input Ã© estÃ¡ vÃ¡lido
   */
  valid: boolean;
}

/**
 * O text input Ã© usado para que o usuÃ¡rio possa inserir,
 * visualizar, selecionar ou pesquisar dados. Frequentemente Ã© utilizado em formulÃ¡rios,
 * mas tambÃ©m pode ser encontrado em outros componentes como tabelas, cards e filtros.
 * Os dados inseridos no text input podem ser numÃ©ricos ou alfanumÃ©ricos (por ex: CPF, e-mail, senha, entre outros).
 *
 * <example-url>../../demo/index.html#/portfolio/input?componentOnly=true</example-url>
 *
 * @example objetos para propriedade de Ã­cones
 *
 * iconOptions = {
 *   icon: 'icon-itaufonts_microfone',
 *   direction: 'left',
 *   description: 'Ãcone microfone',
 *   isClickable: false,
 * };
 *
 * iconOptionsClickable = {
 *   icon: 'icon-itaufonts_alvo',
 *   direction: 'right',
 *   description: 'descriÃ§Ã£o do botÃ£o Ã­cone alvo',
 *   isClickable: true,
 * };
 *
 * @example simples
 * <voxel-input
 *   id="input45"
 *   [id]="'input-tab-focus'"
 *   [label]="'label'"
 *   (blur)="onInputValueBlur($event)"
 *   (focus)="onInputValueFocus($event)"
 *   (inputChange)="onInputChange($event)"></voxel-input>
 *
 * @example password
 * <voxel-input
 *   id="input17"
 *   [label]="'label'"
 *   [type]="'password'"
 *   (blur)="onInputValueBlur($event)"
 *   (focus)="onInputValueFocus($event)"
 *   (inputChange)="onInputChange($event)"></voxel-input>
 *
 * @example number
 * <voxel-input
 *   id="input24"
 *   [maxlength]="10"
 *   [label]="'cÃ³digo promocional'"
 *   [type]="'number'"></voxel-input>
 *
 * @example mascara CPF/CNPJ
 * <voxel-input
 *   id="input25"
 *   [errorMessage]="'preenchimento obrigatÃ³rio'"
 *   [required]="true"
 *   [mask]="'cpf-cnpj'"
 *   [type]="'tel'"
 *   [label]="'CPF/CNPJ'"></voxel-input>
 *
 * @example email
 * <voxel-input
 *   id="input28"
 *   [label]="'email'"
 *   [type]="'email'"></voxel-input>
 */
@Component({
  selector: 'voxel-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class VoxelInputComponent implements OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-input';

  /**
   *  Label do elemento
   */
  @Input()
  label: string;

  /**
   * ID do elemento input
   */
  @Input()
  id: string;

  /**
   * Tipo de mascara
   */
  @Input()
  mask: IInputMaskType = '';

  /**
   * MÃ¡scara customizada
   */
  @Input()
  customMask: string;

  /**
   * Tipo do componente de input
   */
  @Input()
  type = 'text';

  /**
   * Placeholder
   */
  @Input()
  placeholder?: string;

  /**
   *  Desabilitar interaÃ§Ã£o com o componente
   */
  @Input()
  disabled?: boolean;

  /**
   * Informa obrigatoriedade do campo
   */
  @Input()
  required = false;

  /**
   * Inicar input com foco inicial
   */
  @Input()
  autofocus?: boolean;

  /**
   * Mensagem de erro que deverÃ¡ ser mostrada ao usuÃ¡rio.
   * Deve ser utilizado com o campo required="true", serÃ¡ verificado se o campo esta em branco ou undefined,
   * para exibir a mensagem de erro
   */
  @Input()
  errorMessage = 'O preenchimento Ã© obrigatÃ³rio';

  /**
   * Valor para maxlength do input
   */
  @Input()
  maxlength?: number;

  /**
   * Mensagem para outros tratamentos de erros, para tratamento de outros tipos de validaÃ§Ã£o
   */
  @Input()
  customErrorMessage = '';

  /**
   * Tratar a acessibilidade
   */
  @Input()
  a11yLabel = '';

  /**
   * Valor do campo
   */
  @Input()
  value: string | number = '';

  /**
   * OpÃ§Ãµes referentes ao uso de Ã­cone
   *
   * Ex: {
   *  icon: 'nome da classe refetne ao Ã­cone',
   *  direction: 'left' || 'right',
   *  description: 'descriÃ§Ã£o a ser lida em acessibilidade',
   *  isClickable: true || false,
   * }
   */
  @Input()
  iconOptions?: IIconInput;

  /**
   * Flag utilizada para formatar o input com o tamanho de texto para moeda.
   */
  @Input()
  fontCurrency = false;

  /**
   * Mensagem personalizada para erro de data invÃ¡lida
   */
  @Input()
  errorMessageData = 'Digite uma data vÃ¡lida';

  /**
   * Flag para inserir texto de campo opcional apÃ³s o label
   */
  @Input()
  optional = false;

  /**
   * Flag para exibir input com fonte aumentada
   */
  @Input()
  largeSize = false;

  /**
   * Flag para exibir input no tipo de complemento
   */
  @Input()
  complement = false;

  /**
   * Texto a ser lido como complemento
   */
  @Input()
  complementDescription = '';

  /**
   * Texto de apoio abaixo do input
   */
  @Input()
  supportText?: string;

  /**
   * Id referente ao prÃ³ximo input para ser focado
   */
  @Input()
  autoTab?: string;

  /**
   * Flag para exibir input como somente leitura
   */
  @Input()
  readonly?: boolean;

  /**
   * Evento disparado sempre quando o input mudar de valor.
   */
  @Output()
  inputChange = new EventEmitter<IInputEvent>();

  /**
   * Evento disparado quando o input perder o foco.
   */
  @Output()
  blur = new EventEmitter<IInputEvent>();

  /**
   * Evento disparado quando o input obter o foco.
   */
  @Output()
  focus = new EventEmitter<IInputEvent>();

  /**
   * Evento disparado quando Ã­cone Ã© clicÃ¡vel.
   */
  @Output()
  iconClick = new EventEmitter<IInputEvent>();

  /**
   * Flag de indicaÃ§Ã£o se input estÃ¡ com foco
   * @internal
   */
  isInFocus = false;

  /**
   * Flag de indicaÃ§Ã£o se input estÃ¡ com erro
   * @internal
   */
  isError = false;

  /**
   * Flag de indicaÃ§Ã£o se input estÃ¡ vÃ¡lido
   * @internal
   */
  isValid = true;

  /**
   * @internal
   */
  errorMessageCurrent = '';

  /**
   * @internal
   */
  errorMessageCpfCnpj = 'Digite CPF/CNPJ vÃ¡lido';

  /**
   * @internal
   */
  errorMessageCustomMask = 'Digite valor vÃ¡lido';

  /**
   * @internal
   */
  inputValue = '';

  /**
   * @internal
   */
  clearValue = '';

  /**
   * @internal
   */
  a11yId = '-a11y';

  /**
   * @internal
   */
  maskOperators = {};

  /**
   * @internal
   */
  containerClasses = this.getContainerCssClasses();

  /**
   * @internal
   */
  inputContentClasses = this.getInputContentCssClasses();

  /**
   * @internal
   */
  inputClasses = this.getInputCssClasses();

  /**
   * @internal
   */
  complementClasses = this.getComplementCssClasses();

  /**
   * @internal
   */
  supportTextClasses = this.getSupportTextCssClasses();

  /**
   * @internal
   */
  iconClasses = this.getIconCssClasses();

  /**
   * @internal
   */
  segment: Observable<SegmentTypes> = this.segmentService.segment;

  /**
   * @internal
   */
  constructor(
    private validateParam: VoxelValidateParameterService,
    private cpfCnpjValidator: VoxelCpfCnpjValidatorService,
    private dateValidator: VoxelDateValidatorService,
    private customMaskValidator: VoxelCustomMaskValidatorService,
    private segmentService: VoxelSegmentService,
  ) { }

  /**
   * @internal
   */
  ngOnChanges(changes: SimpleChanges) {
    this.checkParameters();
    this.checkRequiredValueError(this.value);
    this.checkValueCustomError(this.value);
    this.maskOperators = this.customMaskValidator.getMaskOperators();

    if (this.mask !== 'custom-mask') {
      this.customMask = '';
    }

    if (this.customMask || this.customMask !== '') {
      this.placeholder = this.customMask;
    }

    if (this.optional && !this.required && this.label) {
      this.label = this.label.concat(' (opcional)');
    }

    if (this.type === 'email') {
      this.placeholder = 'email@email.com';
    }

    if (this.complement) {
      this.type = 'number';
    }

    if (changes.customErrorMessage !== undefined && changes.customErrorMessage.currentValue !== undefined) {
      this.changeErrorMessage(changes.customErrorMessage.currentValue);
    }
    this.updateCssClasses();
  }

  private updateCssClasses() {
    this.containerClasses = this.getContainerCssClasses();
    this.inputContentClasses = this.getInputContentCssClasses();
    this.inputClasses = this.getInputCssClasses();
    this.complementClasses = this.getComplementCssClasses();
    this.supportTextClasses = this.getSupportTextCssClasses();
    this.iconClasses = this.getIconCssClasses();
  }

  private getContainerCssClasses() {
    return {
      'input-container--currency': this.fontCurrency,
      'input-container--date': this.mask === 'date',
      'input-container--error': this.isError === true &&
        this.errorMessageCurrent !== undefined &&
        this.errorMessageCurrent !== '',
      'input-container--large': this.largeSize === true,
      'input-container-complement': this.complement,
      'input-container-read-only': this.readonly,
      'input-container-disabled': this.disabled,
    };
  }

  private getInputContentCssClasses() {
    return {
      'icon-left': this.iconOptions && this.iconOptions.direction === 'left',
      'icon-right': this.iconOptions && this.iconOptions.direction === 'right',
      'input-disabled': this.iconOptions && this.disabled,
    };
  }

  private getInputCssClasses() {
    return {
      'complement-type': this.complement,
    };
  }

  private getComplementCssClasses() {
    return {
      'complement-disabled': this.disabled,
    };
  }

  private getSupportTextCssClasses() {
    return {
      'support-text-disabled': this.disabled,
    };
  }

  /**
   * @internal
   */
  getIconCssClasses() {
    return this.iconOptions ? {
      'icon-clickable': this.iconOptions.isClickable,
    } : undefined;
  }

  private checkParameters() {
    const { selector } = VoxelInputComponent;
    this.validateParam.validateEmptyString(this.id, selector, 'id');
    this.validateParam.validateEmptyString(this.type, selector, 'type');
    this.validateParam.validateTypes(this.errorMessage, selector, 'errorMessage', ['string']);
    this.validateParam.validateTypes(this.value, selector, 'value', ['number', 'string']);
  }

  private checkRequiredValueError(value: string | number | undefined) {
    if (this.verifyValueEmptyOrUndefined(value) && this.required) {
      this.isValid = false;
      this.changeErrorMessage(this.errorMessage);
      return;
    }

    this.isValid = true;
  }

  private checkValueCustomError(value: string | number | undefined) {
    if (!this.verifyValueEmptyOrUndefined(value) && !this.verifyValueEmptyOrUndefined(this.customErrorMessage)) {
      this.isValid = false;
      this.changeErrorMessage(this.customErrorMessage);
      return;
    }

    this.isValid = true;
  }

  private clearErrorMessages() {
    if (this.isValid && !this.customErrorMessage) {
      this.changeErrorMessage('');
    }
  }

  /**
   * @internal
   */
  validateRequired($event: Event) {
    const target = $event.target as HTMLInputElement;
    if (this.mask === 'cpf-cnpj' || this.mask === 'cpf' || this.mask === 'cnpj' || this.mask === 'date' || this.mask === 'custom-mask') {
      return;
    }

    // tslint:disable-next-line:no-non-null-assertion
    if (target.value.length > 0 && target.value.length >= this.maxlength!) {
      target.value = this.inputValue;
      this.value = this.inputValue;
    }

    this.checkRequiredValueError(target.value);
    this.clearErrorMessages();

    if (this.isValid) {
      this.inputChange.emit(this.createEmit($event));
    }
  }

  /**
   * @internal
   */
  validateCpfCnpj(cpfCnpjEvent: ICpfCnpjEvent) {
    this.value = cpfCnpjEvent.value;
    this.clearValue = cpfCnpjEvent.clearMask;

    if (this.cpfCnpjValidator.validateCpfCnpj(this.clearValue) === false) {
      this.isValid = false;
      this.changeErrorMessage(this.errorMessageCpfCnpj);
    } else {
      this.isValid = true;
      this.clearErrorMessages();
    }
    this.inputChange.emit(this.createEmit(cpfCnpjEvent.event));
  }

  /**
   * @internal
   */
  validateCustomMask(customMaskEvent: ICustomMaskEvent) {
    this.value = customMaskEvent.value;
    this.clearValue = customMaskEvent.cleanValue;

    if (this.customMaskValidator.validateCustomMask(customMaskEvent.value, (this.customMask || '')) === false) {
      this.isValid = false;
      this.changeErrorMessage(this.errorMessageCustomMask);
    } else {
      this.isValid = true;
      this.clearErrorMessages();
    }
    this.inputChange.emit(this.createEmit(customMaskEvent.event));
  }

  /**
   * @internal
   */
  validateDate(dateEvent: IDateEvent) {

    this.value = dateEvent.value;
    this.clearValue = dateEvent.clearMask;

    if (this.dateValidator.validateDate(dateEvent.value) === false) {
      this.isValid = false;
      this.changeErrorMessage(this.errorMessageData);
    } else {
      this.isValid = true;
      this.clearErrorMessages();
    }
    this.inputChange.emit(this.createEmit(dateEvent.event));
  }

  /**
   * @internal
   */
  onInputFocus($event: Event) {
    this.isInFocus = true;
    this.isError = true;
    this.focus.emit(this.createEmit($event));
  }

  /**
   * @internal
   */
  onInputBlur($event: Event) {
    this.isInFocus = false;
    this.checkRequiredValueError(this.value);
    this.checkValueCustomError(this.value);

    if (this.type === 'email') {
      this.checkEmailValidation(this.value ? this.value.toString() : '');
    }

    if (this.type === 'number') {
      this.value = isNaN(this.value as number) ? '' : this.value;
    }
    this.updateCssClasses();
    this.blur.emit(this.createEmit($event));
  }

  /**
   * @internal
   */
  onClick($event: Event) {
    if (!this.disabled && this.iconOptions && this.iconOptions.isClickable) {
      this.iconClick.emit(this.createEmit($event));
    }
  }

  /**
   * @internal
   */
  validateCurrency(currencyEvent: ICurrencyFormaterEvent) {
    this.value = currencyEvent.value;
    this.clearValue = currencyEvent.cleanValue;
    this.inputChange.emit(this.createEmit(currencyEvent.event));
  }

  /**
   * @internal
   */
  changeErrorMessage(message = '') {
    this.isError = message !== '';
    this.errorMessageCurrent = message;
  }

  /**
   * @internal
   */
  verifyValueEmptyOrUndefined(value: any): boolean {
    return !value;
  }

  /**
   * @internal
   */
  checkEmailValidation(email: string = '') {
    const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!regex.test(email.toString().toLowerCase())) {
      this.isValid = false;
      this.changeErrorMessage(this.errorMessage);
    }
  }

  /**
   * @internal
   */
  setMaxLength(value: string | number) {
    this.inputValue = value.toString();
  }

  /**
   * @internal
   */
  createEmit($event: Event): IInputEvent {
    return {
      $event,
      id: this.id || '',
      label: this.label || '',
      clearValue: this.clearValue,
      type: this.type,
      value: this.value || '',
      required: this.required,
      valid: this.isValid,
    };
  }

  /**
   * @internal
   */
  getInputInformation() {
    const label = this.label || '';
    const maxLengthDescription = this.maxlength ? `mÃ¡ximo ${this.maxlength} ${this.maxlength <= 1 ? 'caracter' : 'caracteres'}` : '';
    if (this.complement) {
      return `${label} ${this.a11yLabel} ${this.value}, ${this.complementDescription}, ${maxLengthDescription}`;
    }
    return `${label} ${this.a11yLabel}, ${maxLengthDescription}`;
  }

  /**
   * @internal
   */
  getInputDescription() {
    const description = this.supportText || '';
    return `${description}`;
  }
}
