import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { VoxelInternalSearchComponent } from './internal-search.component';
import { VoxelSegmentModule } from './../../services/segment/segment.module';
import { VoxelIconModule } from '../icon/icon.module';

export * from './internal-search.component';

@NgModule({
  imports: [
    VoxelSegmentModule,
    FormsModule,
    CommonModule,
    VoxelIconModule,
  ],
  providers: [
  ],
  declarations: [
    VoxelInternalSearchComponent,
  ],
  exports: [
    VoxelInternalSearchComponent,
  ],
})

export class VoxelInternalSearchModule { }
