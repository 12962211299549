import { NgModule } from '@angular/core';

import { VoxelAutoHeightDirective } from './auto-height.directive';

@NgModule({
  declarations: [VoxelAutoHeightDirective],
  exports: [VoxelAutoHeightDirective],
})
export class VoxelAutoHeightDirectiveModule { }

export * from './auto-height.directive';
