import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelValidateParameterModule } from '../../services/validate-parameter/validate-parameter.module';
import { VoxelSegmentModule } from './../../services/segment/segment.module';
import { VoxelButtonComponent } from './button.component';
import { VoxelIconModule } from '../icon/icon.module';

export * from './button.component';

@NgModule({
  imports: [
    CommonModule,
    VoxelValidateParameterModule,
    VoxelSegmentModule,
    VoxelIconModule,
  ],
  declarations: [VoxelButtonComponent],
  exports: [VoxelButtonComponent],
})
export class VoxelButtonModule { }
