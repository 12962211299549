import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelValidateParameterModule } from './../../services/validate-parameter/validate-parameter.module';
import { VoxelLinkComponent } from './link.component';

export * from './link.component';

@NgModule({
  imports: [
    CommonModule,
    VoxelValidateParameterModule,
  ],
  providers: [
  ],
  declarations: [VoxelLinkComponent],
  exports: [VoxelLinkComponent],
})
export class VoxelLinkModule { }
