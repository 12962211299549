import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelCpfCnpjDirective } from './cpf-cnpj.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [VoxelCpfCnpjDirective],
  exports: [VoxelCpfCnpjDirective],
})
export class VoxelCpfCnpjDirectiveModule { }

export * from './cpf-cnpj.directive';
