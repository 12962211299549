import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelMaxlengthFormatterDirective } from './maxlength-formatter.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [VoxelMaxlengthFormatterDirective],
  exports: [VoxelMaxlengthFormatterDirective],
})
export class VoxelMaxlengthFormatterDirectiveModule { }

export * from './maxlength-formatter.directive';
