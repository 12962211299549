import { Component, Input } from '@angular/core';

/**
 * PadrÃµes de tamanhos aceitos pelo componente de Ã­cone
 */
export type IIconSize = 'S' | 'M' | 'L  ';

/**
 * Componente de Ã­cone usado em elementos de interface, disponibilizado nos tamanhos pequeno "S", mÃ©dio "M" e grande "L"
 *
 * <example-url>../../demo/index.html#/portfolio/icon?componentOnly=true</example-url>
 *
 * @example
 * <voxel-icon>
 *    nome_do_Ã­cone
 * </voxel-icon>
 *
 * @example Leitor de tela habilitado
 * <voxel-icon
 *  a11yHidden="false"
 *  a11yLabel="DescriÃ§Ã£o do Ã­cone">
 *    nome_do_Ã­cone
 * </voxel-icon>
 *
 * @example Ãcone com alteraÃ§Ã£o de tamanho
 * <voxel-icon
 *  size="S">
 *    nome_do_Ã­cone
 * </voxel-icon>
 *
 */

@Component({
  selector: 'voxel-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class VoxelIconComponent {

  /**
   * @internal
   */
  static selector = 'voxel-icon';

  /**
   * Tamanho do Ã­cone
   */
  @Input()
  size: IIconSize = 'M';

  /**
   * Desabilitar Leitor de Tela para o Ã­cone
   */
  @Input()
  a11yHidden = true;

  /**
   * DescriÃ§Ã£o do Ã­cone para acessibilidade
   */
  @Input()
  a11yLabel = '';

}
