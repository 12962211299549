import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, OnChanges, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { SegmentTypes, VoxelSegmentService } from './../../services/segment/segment.module';

/**
 * Dados dos eventos do navigation
 */
export interface INavigationEvent {
  /**
   * Evento de click do navigation
   */
  $event: Event;
  /**
   * Lista de abas com o tipo: Array de string
   */
  tabs: string[];
  /**
   * Aba selecionada
   */
  selected: string;
  /**
   * Valor da aba ativo
   */
  selectedIndex: number;
}

/**
 *  Componente base para navegaÃ§Ã£o
 *  @internal
 *
 *  @example Normal
 *  <voxel-navigation
 *   [id]="id"
 *   [tabs]="tabs"
 *   [selectedIndex]="'1'"
 *   (click)="onClickTab($event)"></voxel-navigation>
 */
@Component({
  selector: 'voxel-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class VoxelNavigationComponent implements OnInit, OnChanges, AfterViewInit {

  /**
   * IdentificaÃ§Ã£o do componente
   */
  @Input()
  id: string;

  /**
   * Lista de Abas
   *
   * ```js
   * // Exemplo
   * ['botÃ£o 1', 'BotÃ£o 2', 'BotÃ£o 3']
   * ```
   */
  @Input()
  tabs: string[];

  /**
   * NÃºmero da aba/passo atual
   */
  @Input()
  selectedIndex = 1;

  /**
   * Evento disparado sempre que selecionar uma aba.
   *
   * **Na opÃ§Ã£o de steps esse evento nÃ£o Ã© acionado devido a regra de uso**
   */
  @Output()
  navigationClick = new EventEmitter<INavigationEvent>();

  /**
   * @internal
   */
  @ViewChild('tabsContent') tabsContent: ElementRef;

  /**
   * @internal
   */
  tabHighlightLeft = 0;

  /**
   * @internal
   */
  tabHighlightWidth = 0;

  /**
   * @internal
   */
  maxLengthCharacters = 30;

  /**
   * @internal
   */
  arrayAmount: string[] = [];

  /**
   * @internal
   */
  tabSelected?: string;

  /**
   * @internal
   */
  tabsArray?: object;

  /**
   * @internal
   */
  segment: Observable<SegmentTypes> = this.segmentService.segment;

  /**
   * @internal
   */
  constructor(
    private segmentService: VoxelSegmentService,
  ) { }

  /**
   * @internal
   */
  ngOnInit() {
    this.arrayAmount = this.tabs.map((tab: any) => {
      return tab.trim().substring(0, this.maxLengthCharacters);
    });

    this.setCurrentTab();
  }

  /**
   * @internal
   */
  ngOnChanges() {
    this.tabSelected = this.tabs[0];
  }

  /**
   * @internal
   */
  ngAfterViewInit() {
    this.setTabHighlight();
  }

  /**
   * @internal
   */
  setTabHighlight() {
    setTimeout(() => {
      const currentTab = this.tabsContent.nativeElement.querySelector('.tabs-content__tab__checked');
      this.tabHighlightLeft = currentTab.offsetLeft;
      this.tabHighlightWidth = currentTab.offsetWidth;
    }, 50);
  }

  /**
   * @internal
   */
  onClickTab($event: Event, tab: string, tabIndex: number) {
    this.tabSelected = tab;
    this.setSelectedCurrentTab(tabIndex, tab);
    this.setCurrentTab();
    this.setTabHighlight();

    this.navigationClick.emit({
      $event,
      tabs: this.arrayAmount,
      selected: tab,
      selectedIndex: this.selectedIndex,
    });
  }

  /**
   * @internal
   */
  setSelectedCurrentTab(tabIndex: number, tab: string) {
    this.arrayAmount.forEach((name: string) => {
      if (name === tab) {
        this.selectedIndex = tabIndex + 1;
      }
    });
  }

  /**
   * @internal
   */
  setCurrentTab() {
    this.tabsArray = this.arrayAmount.map((name, index) => {
      const obj = {
        value: name,
        active: '',
      };

      if (this.selectedIndex === index + 1) {
        obj.active = 'tabs-content__tab__checked tabs-content__tab__current';
      }

      if (this.selectedIndex === 1 && index === 0) {
        obj.active = 'tabs-content__tab__checked tabs-content__tab__current';
      }

      return obj;
    });
  }

}
