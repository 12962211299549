import { NgModule } from '@angular/core';

import { VoxelDateValidatorService } from './date-validator.service';

@NgModule({
  providers: [VoxelDateValidatorService],
})
export class VoxelDateValidatorServiceModule { }

export * from './date-validator.service';
