import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  OnChanges,
  ViewChild,
} from '@angular/core';

import { VoxelStepComponent } from './step/step.component';
import { VoxelLinkComponent } from '../link/link.component';
import { Observable } from 'rxjs/internal/Observable';
import { VoxelValidateParameterService } from './../../services/validate-parameter/validate-parameter.service';

export type IStepperConclusionType = 'success' | 'pending';
export type IStepperSyncType = 'sync' | 'async';

/**
 * Componente Stepper
 *
 * O Stepper Ã© uma representaÃ§Ã£o visual usada para dar a ideia de progresso,
 * localizaÃ§Ã£o e quantidade de etapas necessÃ¡rias para a conclusÃ£o de uma tarefa longa ou complexa.
 *
 * <example-url>../../demo/index.html#/portfolio/stepper?componentOnly=true</example-url>
 *
 * @example Success
 * <voxel-stepper
 *   conclusionMessage="transaÃ§Ã£o efetivada com sucesso!"
 *   conclusionLinkText="deseja alterar ou excluir cobranÃ§a?"
 *   conclusionLinkHref="#"
 *   conclusionLinkLabel="deseja alterar ou excluir cobranÃ§a?"
 * >
 *   <voxel-step label="Lorem">
 *     <!-- conteudo do step aqui -->
 *     <voxel-button voxelStepperNavigation="prev" secondary="true">voltar</voxel-button>
 *     <voxel-button voxelStepperNavigation="next">continuar</voxel-button>
 *   </voxel-step>
 * </voxel-stepper>
 *
 * @example Pending
 * <voxel-stepper
 *   conclusionType="pending"
 *   conclusionMessage="transaÃ§Ã£o efetivada com sucesso!"
 * >
 *   <voxel-step label="Lorem">
 *     <!-- conteudo do step aqui -->
 *     <voxel-button voxelStepperNavigation="prev" secondary="true">voltar</voxel-button>
 *     <voxel-button voxelStepperNavigation="next">continuar</voxel-button>
 *   </voxel-step>
 * </voxel-stepper>
 *
 * @example Async
 * <voxel-stepper
 *   conclusionType="pending"
 *   conclusionMessage="boleto pendente para aprovaÃ§Ã£o direta do seu aprovador do centro de custo."
 * >
 *   <voxel-step label="Lorem">
 *     <!-- conteudo do step aqui -->
 *     <voxel-button voxelStepperNavigation="prev" secondary="true">voltar</voxel-button>
 *     <voxel-button voxelStepperNavigation="next" [voxelStepperNavigationAsync]="somethingAsync()">continuar</voxel-button>
 *   </voxel-step>
 * </voxel-stepper>
 *
 */
@Component({
  selector: 'voxel-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class VoxelStepperComponent implements AfterContentInit, OnChanges {

  /**
   * @internal
   */
  static selector = 'voxel-stepper';

  /**
   * @internal
   */
  @ViewChild('tabList') tabList: ElementRef;

  /**
   * @internal
   */
  @ViewChild('conclusionLink') conclusionLink: VoxelLinkComponent;

  /**
   * @internal
   */
  @ContentChildren(VoxelStepComponent) stepComponents: QueryList<VoxelStepComponent>;

  /**
   * DescriÃ§Ã£o da lista de abas/passos para leitores de tela
   */
  @Input()
  tabListDescription = '';

  /**
   * Mensagem a ser exibida ao completar o stepper
   */
  @Input()
  conclusionMessage = '';

  /**
   * Tipo de conclusÃ£o de mensagem
   */
  @Input()
  conclusionType: IStepperConclusionType = 'success';

  /**
   * Texto do link em caso de mensagens do tipo sucesso
   */
  @Input()
  conclusionLinkText = '';

  /**
   * Texto de acessibilidade do link
   */
  @Input()
  conclusionLinkLabel = '';

  /**
   * Destino do link em caso de mensagens do tipo sucesso
   */
  @Input()
  conclusionLinkHref = '';

  /**
   * NÃºmero da aba/passo atual
   * @internal
   */
  currentStep = 0;

  /**
   * @internal
   */
  steps: string[] = [];

  /**
   * @internal
   */
  progressBarWidth = 0;

  /**
   * @internal
   */
  syncType: IStepperSyncType = 'sync';

  /**
   * @internal
   */
  instanceId = Math.random().toString(36).substr(2, 9);

  constructor(
    private validateParam: VoxelValidateParameterService,
  ) { }

  /**
   *
   * @internal
   */
  ngAfterContentInit() {
    this.steps = this.stepComponents.map(item => item.label);
    this.setVisiblePanel(this.currentStep);

    this.stepComponents.forEach((step, index) => {
      step.labelledBy = this.generateId(step.label, 'tab', index);
      step.id = this.generateId(step.label, 'tabpanel', index);
    });

    this.setProgressBarWidth();
    this.validateRequiredParams();
  }

  /**
   * @internal
   */
  ngOnChanges() {
    this.validateRequiredParams();
  }

  validateRequiredParams() {
    const requiredParams = ['conclusionMessage'];

    if (this.conclusionType === 'success') {
      requiredParams.push('conclusionLinkText');
      requiredParams.push('conclusionLinkLabel');
      requiredParams.push('conclusionLinkHref');
    }

    this.validateParam.validatePropertyOnObject(
      this,
      VoxelStepperComponent.selector,
      requiredParams,
    );
  }

  /**
   * @internal
   */
  @HostListener('window:resize')
  onResize() {
    this.setProgressBarWidth();
  }

  /**
   * @internal
   */
  setProgressBarWidth() {
    setTimeout(() => {
      const width = this.currentTab ? this.currentTab.offsetLeft + this.currentTab.offsetWidth : 0;
      this.progressBarWidth = width;
    }, 50);
  }

  /**
   * @internal
   */
  get currentTab() {
    return this.tabList.nativeElement.querySelector('li.current');
  }

  /**
   * @internal
   */
  nextStep(resolve?: Observable<any> | Promise<any>) {
    this.syncType = resolve ? 'async' : 'sync';

    if (this.currentTab.nextElementSibling) {
      this.currentTab.nextElementSibling.focus();
    }

    this.currentStep++;
    this.setVisiblePanel(this.currentStep, resolve);
    this.setProgressBarWidth();
  }

  /**
   * @internal
   */
  prevStep() {
    this.syncType = 'sync';

    if (this.currentTab.previousElementSibling) {
      this.currentTab.previousElementSibling.focus();
    }

    this.currentStep--;
    this.setVisiblePanel(this.currentStep);
    this.setProgressBarWidth();
  }

  /**
   * @internal
   */
  setVisiblePanel(id: number, resolve?: Observable<any> | Promise<any>) {
    this.stepComponents.forEach((step, index) => {
      (id === index) ? this.showStep(step, resolve) : step.hide();
    });
  }

  /**
   * @internal
   */
  showStep(step: VoxelStepComponent, resolve?: Observable<any> | Promise<any>) {
    if (!resolve) {
      return step.show();
    }

    const promise = resolve instanceof Promise ? resolve : resolve.toPromise();

    promise
      .then(() => {
        this.syncType = 'sync';
        step.show();
      })
      .catch(() => this.prevStep());
  }

  /**
   * @internal
   */
  generateId(label: string, prefix: string, index: number) {
    const labelNormalized = label
      .toLowerCase()
      .split(' ')
      .join('-');

    return `${prefix}-${labelNormalized}-${index}_${this.instanceId}`;
  }

  /**
   * @internal
   */
  changeContrastOfLinkInCaseSuccess(event: AnimationEvent) {
    const isContrastSuccess = event.animationName === 'contrast-success';
    if (isContrastSuccess) {
      this.conclusionLink.darker = false;
    }
  }
}
