import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VoxelDateDirective } from './date.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [VoxelDateDirective],
  exports: [VoxelDateDirective],
})
export class VoxelDateDirectiveModule { }

export * from './date.directive';
