import { Injectable } from '@angular/core';

/**
 * ServiÃ§co para validaÃ§Ã£o de Datas
 */
@Injectable()
export class VoxelDateValidatorService {

  /**
   * Valida formato da data
   *
   * @param date Data a ser validada
   * @returns Passou um nÃ£o na validaÃ§Ã£o
   */
  validateDate(date: string): boolean {
    const dateLength = 8;
    let dateValid: any;
    let dateBreak: any;

    if (date === '' || date === undefined) {
      return false;
    }

    // tslint:disable-next-line:no-parameter-reassignment
    date = date.replace(/\D/g, '');

    if (date.length !== dateLength) {
      return false;
    }

    dateValid = this.dateCreate(date);

    if (dateValid !== 'Invalid Date') {
      dateBreak = this.dateBreak(dateValid);
      return (date === `${dateBreak.day}${dateBreak.month}${dateBreak.year}`);
    }

    return false;
  }

  private dateIncrement(date: any) {
    return Number(date) > 9 ? date : `0${date}`;
  }

  dateCreate(date: string) {
    const dateBreak = this.dateBreakString(date);
    return new Date(dateBreak.year, Number(dateBreak.month) - 1, dateBreak.day, 0, 0, 0);
  }

  dateBreakString(date: any) {
    // tslint:disable-next-line:no-parameter-reassignment
    date = date.replace(/\D/g, '');
    return {
      day: date.substr(0, 2),
      month: date.substr(2, 2),
      year: date.substr(4, 8),
    };
  }

  dateBreak(date: Date) {
    return {
      day: this.dateIncrement(date.getDate()),
      month: this.dateIncrement(Number(date.getMonth()) + 1),
      year: date.getFullYear(),
    };
  }

  dateFormat(date: Date) {
    const dateBreak = this.dateBreak(date);
    return `${dateBreak.day}/${dateBreak.month}/${dateBreak.year}`;
  }
}
