import { Injectable } from '@angular/core';

export interface IFormatNumber {
  formatValue: string;
  number: number;
}

/**
 * ServiÃ§o para formataÃ§Ã£o de nÃºmeros
 */
@Injectable()
export class VoxelNumberFormatterService {

  /**
   * Formata nÃºmero de texto sem mascara
   *
   * @param value NÃºmero em formato de texto a ser formatado
   * @returns NÃºmero jÃ¡ formatado em formato de texto
   */
  formatToNumberWithoutMask(value: string): string {
    return value.replace(/\D/g, '')
    .replace(/(\d{1})(\d{10})$/, '$1$2')
    .replace(/(\d{1})(\d{8})$/, '$1$2')
    .replace(/(\d{1})(\d{5})$/, '$1$2')
    .replace(/(\d{1})(\d{1,2})$/, '$1.$2');
  }

  /**
   * Formata nÃºmero de texto em formato de moeda
   *
   * @param value NÃºmero em formato de texto a ser formatado
   * @returns NÃºmero jÃ¡ formatado em formato de texto
   */
  currency(value: string): IFormatNumber {
    const newValue = value.toString().replace(/\D/g, '').replace(/^0+/, '').substring(0, 11);

    return newValue.length > 2 ?
      this.applyCurrencyPrefix(this.applyCurrencyMask(newValue)) :
      this.applyMaskCents(newValue, value);
  }

  private applyCurrencyPrefix(value: string): IFormatNumber {
    const currentValue = value === '' || value === undefined ? '0,00' : value;
    const currentCleanValue = this.cleanString(currentValue);
    return {
      formatValue: 'R$ ' + currentValue,
      number: Number(currentCleanValue.split(',')[0] + '.' + currentCleanValue.split(',')[1]),
    };

  }

  private applyCurrencyMask(value: string) {
    return value.replace(/(\d{1})(\d{8})$/, '$1.$2')
      .replace(/(\d{1})(\d{5})$/, '$1.$2')
      .replace(/(\d{1})(\d{1,2})$/, '$1,$2');
  }

  private applyMaskCents(newValue: string, value: string) {
    const splitValue = value.toString().split('.');

    if (splitValue.length > 1) {
      return this.applyCurrencyPrefix(splitValue[0] + ',' + newValue[1] + (newValue[1].length === 1 ? '0' : ''));
    }

    let maskedValue = newValue;
    if (newValue.length === 1) {
      maskedValue = `0,0${newValue}`;
    }

    if (newValue.length === 2) {
      maskedValue = `0,${newValue}`;
    }

    return this.applyCurrencyPrefix(maskedValue);
  }

  private cleanString(inputValue: string) {
    const value = inputValue.replace('R$ ', '');
    return value.replace(/\./g, '');
  }

}
