import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { SegmentTypes, VoxelSegmentService } from './../../services/segment/segment.module';

/**
 * O componente Internal Search (busca interna) deve ser usado em caso de pesquisa mais focada,
 * o usuÃ¡rio verÃ¡ resultados ligados somente ao contexto de navegaÃ§Ã£o do componente ou seÃ§Ã£o
 * que estÃ¡ utilizando. NÃ£o Ã© uma busca que encontra resultados no site como um todo,
 * para isso usamos a busca posicionada no header.
 *
 * <example-url>../../demo/index.html#/portfolio/internalsearch?componentOnly=true</example-url>
 *
 * @example Normal
 * <voxel-internal-search>
 * </voxel-internal-search>
 *
 * @example Desabilitado
 * <voxel-internal-search
 *  [disabled]="true">
 * </voxel-internal-search>
 */
@Component({
  selector: 'voxel-internal-search',
  templateUrl: './internal-search.component.html',
  styleUrls: ['./internal-search.component.scss'],
})
export class VoxelInternalSearchComponent {

  /**
   * @internal
   */
  static selector = 'voxel-internal-search';

  /**
   * Define o valor buscado pelo componente
   */
  @Input()
  query = '';

  /**
   * Desabilitar interaÃ§Ã£o com o componente
   */
  @Input()
  disabled = false;

  /**
   * Evento disparado quando altera o valor do input
   */
  @Output()
  changeValue = new EventEmitter<string>();

  /**
   * @internal
   */
  segment: Observable<SegmentTypes> = this.segmentService.segment;

  /**
   * @internal
   */
  constructor(
    private segmentService: VoxelSegmentService,
  ) { }

  /**
   * @internal
   */
  clearInput(inputSearch: HTMLInputElement) {
    this.query = '';
    inputSearch.value = this.query;
    inputSearch.focus();

    this.changeValue.emit('');
  }

  /**
   * @internal
   */
  onChangeValue(value: string) {
    this.changeValue.emit(value);
  }
}
