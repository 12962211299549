import { NgModule } from '@angular/core';

import { VoxelCpfCnpjValidatorService } from './cpf-cnpj-validator.service';

@NgModule({
  providers: [VoxelCpfCnpjValidatorService],
})
export class VoxelCpfCnpjValidatorServiceModule { }

export * from './cpf-cnpj-validator.service';
