import { NgModule } from '@angular/core';

import { VoxelValidateParameterModule } from '../../services/validate-parameter/validate-parameter.module';
import { VoxelBadgeComponent } from './badge.component';

export * from './badge.component';

@NgModule({
  imports: [
    VoxelValidateParameterModule,
  ],
  declarations: [VoxelBadgeComponent],
  exports: [VoxelBadgeComponent],
})
export class VoxelBadgeModule { }
